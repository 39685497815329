/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-file no-use-before-define
import * as React from 'react';

function SvgChat(props) {
  return (
    <svg width={54} height={54} viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.433 16.942h23.884a1.491 1.491 0 010 2.981H13.433a1.491 1.491 0 010-2.98zM13.433 24.904h15.923a1.491 1.491 0 010 2.98H13.433a1.491 1.491 0 010-2.98z"
        fill="inherit"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.615 43.808l-12.02 9.6c-.287.228-.65.352-1.018.352-.229 0-.463-.05-.677-.144-.558-.25-.9-.78-.9-1.347V12.462C0 8.367 3.482 5 7.808 5h37.384C49.518 5 53 8.367 53 12.461v23.885c0 4.095-3.482 7.462-7.808 7.462H14.615zM3.154 49.06l9.867-7.882a1.63 1.63 0 011.018-.352h31.153c2.55 0 4.654-1.992 4.654-4.48V12.46c0-2.489-2.105-4.48-4.654-4.48H7.808c-2.55 0-4.654 1.991-4.654 4.48v36.6z"
        fill="inherit"
      />
    </svg>
  );
}

export default SvgChat;
