/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-file no-use-before-define
import * as React from 'react';

function SvgDigitalproduct(props) {
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#attachment_svg__clip0)">
        <path
          d="M10.423 0A5.43 5.43 0 005 5.423v10.268a.663.663 0 101.326 0V5.422a4.102 4.102 0 014.097-4.097 4.102 4.102 0 014.097 4.097V16.01a2.669 2.669 0 01-2.665 2.665c-.011 0-.021.006-.032.006-.012 0-.02-.006-.032-.006a2.669 2.669 0 01-2.666-2.665v-6.35a1.3 1.3 0 012.596 0v6.031a.663.663 0 101.326 0V9.66a2.627 2.627 0 00-2.624-2.624A2.627 2.627 0 007.8 9.659v6.35c0 2.2 1.79 3.991 3.992 3.991.011 0 .02-.006.032-.006.01 0 .02.006.032.006 2.2 0 3.991-1.79 3.991-3.991V5.423A5.43 5.43 0 0010.423 0z"
          fill="#000"
        />
      </g>
      <defs>
        <clipPath id="attachment_svg__clip0">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgDigitalproduct;
