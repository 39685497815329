import React from 'react';
import { IProduct, ServiceType } from '../helpers/initialState';
import { useHistory, useLocation } from 'react-router-dom';
import ChatCard from './components/ChatCard';
import EventCard from './components/EventCard';
import DigitalProductCard from './components/DigitalProductCard';
import CallCard from './components/CallCard';

interface IServiceCard extends IProduct {
  handleCardClick?: (event: React.SyntheticEvent, _id: string | undefined) => void;
  showClickButton?: boolean;
  appearance?: string;
}
export interface ServiceTypeCard extends IProduct {
  handleCardClick?: (event: React.SyntheticEvent, _id: string | undefined) => void;
  showClickButton?: boolean;
  appearance?: string;
}

const AddServiceCardComponentsMap: { [key: string]: React.FC<ServiceTypeCard> } = {
  chat: ChatCard,
  digitalProduct: DigitalProductCard,
  event: EventCard,
  call: CallCard,
};

const defaultImage = (
  service: ServiceType.Call | ServiceType.Chat | ServiceType.DigitalProduct | ServiceType.Event | undefined,
): string => {
  return `url('/img/${service}-service-public.svg')`;
};

const ServiceCard: React.FC<IServiceCard> = (props) => {
  const location = useLocation().pathname;
  const { service, title, description, bannerImage, isActive } = props;
  const history = useHistory();
  const onCardActionAreaClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (props.showClickButton && props.handleCardClick) {
      history.push(`${location}/p/${props._id}`);
    }
  };
  const appliedStyles = props.appearance && JSON.parse(props.appearance);
  return (
    <div className={`max-w-sm w-full sm:h-full ${isActive ? '' : 'hidden'}`}>
      <div
        className={`relative flex flex-wrap flex-col justify-between sm:h-full m-2 my-6 md:mx-0 md:my-2 shadow-card bg-white ${
          props.showClickButton && appliedStyles?.buttonRounded ? appliedStyles?.buttonRounded : 'rounded-xl'
        }`}
        onClick={(e) => onCardActionAreaClick(e)}>
        <div
          className={`bg-no-repeat bg-center bg-cover h-48 ${
            props.showClickButton && appliedStyles?.buttonRounded ? appliedStyles?.buttonRounded : 'rounded-xl'
          } rounded-b-none`}
          style={{
            backgroundImage: bannerImage ? `url(${bannerImage})` : defaultImage(service.serviceType),
          }}></div>
        <div className="flex flex-1 flex-wrap flex-col justify-between m-4">
          <div className="flex flex-col">
            <div
              className="text-xl font-bold"
              style={appliedStyles?.color && props.showClickButton ? { color: appliedStyles?.color } : {}}>
              {title}
            </div>
            <div className="mt-1 text-sm font-medium text-gray-3 break-words">{description}</div>
          </div>
          <div className="">
            {service?.serviceType &&
              React.createElement(AddServiceCardComponentsMap[service?.serviceType], {
                ...props,
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
