/* eslint-disable @typescript-eslint/no-explicit-any */
export interface IUserDetails {
  email?: string;
  contact?: string;
}

export interface IProcessConfig {
  CALLBACK_URL: string;
  CHANNEL_ID: string;
  CHECKSUMHASH: string;
  CUST_ID: string;
  EMAIL: string;
  INDUSTRY_TYPE_ID: string;
  MID: string;
  MOBILE_NO: string;
  ORDER_ID: string;
  TXN_AMOUNT: string;
  WEBSITE: string;
}
export interface IActivateProduct {
  orderId?: string;
  redirectToPaymentGateway?: boolean;
  processConfig?: IProcessConfig;
}
export interface IPrice {
  amount?: number;
  currency?: string;
  txn_type?: string;
  formattedAmount?: string;
}
export interface ILink {
  id?: string;
  title: string;
  url: string;
}

export interface IChatLink {
  sms?: string;
  messenger?: string;
  whatsapp?: string;
  signal?: string;
  telegram?: string;
  others?: string;
  email?: string;
  url?: string;
}

export enum PaymentMode {
  Free = 'free',
  Paid = 'paid',
}

export interface IAssets {
  file?: File;
  fileType?: string;
  url?: string;
}

export enum ServiceType {
  Chat = 'chat',
  Event = 'event',
  DigitalProduct = 'digitalProduct',
  Call = 'call',
}

export interface IService {
  serviceType?: ServiceType.Call | ServiceType.Chat | ServiceType.DigitalProduct | ServiceType.Event;
  links?: ILink | IChatLink;
  date?: string | number;
  url?: string;
  email?: string;
  assets?: IAssets;
  duration?: string;
  scheduleType?: string;
}
export interface IProduct {
  bannerImage?: string;
  _id?: string;
  user?: string;
  isActive: boolean;
  title: string;
  description: string;
  paymentMode: PaymentMode.Free | PaymentMode.Paid;
  service: IService;
  price: IPrice;
  chronicalOrder?: number;
}

export interface ISocialLinks {
  link: string;
  sequence?: number;
  title: string;
}

export interface IUserProfile {
  _id: string;
  emailVerified: boolean;
  email: string;
  userName: string;
  setupStage: number;
  socialLinks: ISocialLinks[];
  profileUrls: ILink[];
  description: string;
  name: string;
  profileImage?: string;
  appearance?: string;
  res?: string;
}
export interface IAlert {
  message?: string;
  type?: string;
}

export interface IApiError {
  message: string;
  status?: boolean;
  statusCode?: number;
  result?: {
    type: string;
    message: string;
  };
}
export interface IUser {
  email?: string;
  loggingIn?: boolean;
  registering?: boolean;
  loggedIn?: boolean;
  signinError?: string;
  loginError?: string;
}

export interface IPublicProfile {
  email: string;
  name: string;
  description: string;
  socialLinks: ISocialLinks[];
  profileUrls: ILink[];
  products: IProduct[];
  appearance: string;
  loading?: boolean;
  profileType: string;
}

export interface IBankDetails {
  accountName: string;
  ifsc: string;
  accountNumber: string;
  //bankName: string;
}

export interface InitialStore {
  alert: IAlert;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentProduct?: any;
  products: IProduct[] | [];
  loading: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  userProfile?: IUserProfile;
  user?: IUser;
  publicProfile?: IPublicProfile;
  activateService?: IActivateProduct;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  insights: any;
  bankDetails?: any;
}

export const initialState: InitialStore = {
  alert: {},
  currentProduct: {},
  products: [],
  loading: false,
  userProfile: {
    setupStage: 0,
    userName: '',
    email: '',
    profileUrls: [],
    socialLinks: [],
    name: '',
    _id: '',
    emailVerified: false,
    description: '',
  },
  user: {},
  activateService: {},
  insights: {},
};
