/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import * as activateServiceConatants from '../Constants';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IPostActivateServiceRequest, IPostActivateServiceSuccess, IPostActivateServiceFailure } from './action.types';
import { activateService } from '../Services';
import { IActivateProduct, IUserDetails } from '../helpers/initialState';
import { history } from '../helpers/history';
import { APP_ROUTES } from '../Utils/routeConstant';
import toast from 'react-hot-toast';

export const postActivateServiceRequest = (userDetails: IUserDetails): IPostActivateServiceRequest => {
  return { type: activateServiceConatants.POST_ACTIVATE_REQUEST, userDetails };
};

export const postActivateServiceSuccess = (userDetailsResponse: IActivateProduct): IPostActivateServiceSuccess => {
  return { type: activateServiceConatants.POST_ACTIVATE_SUCCESS, userDetailsResponse };
};

export const postActivateServiceFailure = (error: string): IPostActivateServiceFailure => {
  return { type: activateServiceConatants.POST_ACTIVATE_FAILURE, error };
};

export const postActivateService = (
  userDetails: IUserDetails,
  username: string | undefined,
  selectedProduct: string | undefined,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(postActivateServiceRequest(userDetails));
    await activateService
      .postActivateService(userDetails, selectedProduct)
      .then((result: any) => {
        if (result.redirectToPaymentGateway === true) {
          activateService.postPayment(result);
        } else if (result.redirectToPaymentGateway === false) {
          toast.success('Success');
          history.push(`${APP_ROUTES.THANKYOU}?p=${username}`);
        }
        return result;
      })
      .then((userDetailsResponse: IActivateProduct) => dispatch(postActivateServiceSuccess(userDetailsResponse)))
      .catch((error: string) => {
        dispatch(postActivateServiceFailure(error));
        window.location.reload();
      });
  };
};

export const activateServiceActions = {
  postActivateService,
};
