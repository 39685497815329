/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-file no-use-before-define
import * as React from 'react';

function SvgTime(props) {
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2.417a7.583 7.583 0 100 15.166 7.583 7.583 0 000-15.166zM.917 10a9.083 9.083 0 1118.166 0A9.083 9.083 0 01.917 10z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 4.25a.75.75 0 01.75.75v4.536l2.919 1.46a.75.75 0 01-.671 1.341l-3.333-1.666A.75.75 0 019.25 10V5a.75.75 0 01.75-.75z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgTime;
