/*eslint no-duplicate-case: "error"*/
import * as userProfileConstants from '../Constants';
import {
  IGetUserRequest,
  IGetUserSuccess,
  IGetUserFailure,
  IPostProfileRequest,
  IPostProfileSuccess,
  IPostProfileFailure,
  IPostLoginProfileFailure,
  IPostLoginProfileRequest,
  IPostLoginProfileSuccess,
  IGetTwitterUserFailure,
  IGetTwitterUserRequest,
  IGetTwitterUserSuccess,
} from '../Actions/action.types';
import { initialState, IUserProfile } from '../helpers/initialState';

const transformState = (
  prevstate: IUserProfile | undefined,
  response: IUserProfile | undefined,
): IUserProfile | undefined => {
  const newResponse = prevstate && { ...prevstate, ...response };
  const appearence = prevstate?.appearance && JSON.parse(prevstate?.appearance);
  if (newResponse && prevstate && response && response.profileImage) {
    newResponse.appearance = JSON.stringify({ ...appearence, profileImage: response.profileImage });
  }

  return newResponse || prevstate;
};

export const userProfile = (
  state = initialState?.userProfile,
  action:
    | IGetUserRequest
    | IGetUserSuccess
    | IGetUserFailure
    | IPostProfileRequest
    | IPostProfileSuccess
    | IPostProfileFailure
    | IPostLoginProfileFailure
    | IPostLoginProfileRequest
    | IPostLoginProfileSuccess
    | IGetTwitterUserFailure
    | IGetTwitterUserRequest
    | IGetTwitterUserSuccess,

  // eslint-disable-next-line @typescript-eslint/ban-types
): IUserProfile | undefined | {} => {
  switch (action.type) {
    case userProfileConstants.GET_USER_REQUEST:
      return { ...state, res: 'loading' };
    case userProfileConstants.GET_USER_SUCCESS:
      return { ...state, ...action.response, res: 'success' };
    case userProfileConstants.GET_USER_FAILURE:
      return { ...state, res: 'failure' };
    case userProfileConstants.GET_TWITTER_USER_REQUEST:
      return { ...state };
    case userProfileConstants.GET_TWITTER_USER_SUCCESS:
      return transformState(state, action.response) ? { ...transformState(state, action.response) } : { ...state };
    case userProfileConstants.GET_TWITTER_USER_FAILURE:
      return { ...state };
    case userProfileConstants.POST_PROFILE_REQUEST:
      return { ...state };
    case userProfileConstants.POST_PROFILE_SUCCESS:
      return { ...state, ...action.userProfile };
    case userProfileConstants.POST_PROFILE_FAILURE:
      return { ...state };
    case userProfileConstants.POST_LOGIN_PROFILE_REQUEST:
      return { ...state };
    case userProfileConstants.POST_LOGIN_PROFILE_SUCCESS:
      return { ...state, ...action.user };
    case userProfileConstants.POST_LOGIN_PROFILE_FAILURE:
      return { ...state };
    default:
      return state;
  }
};
