import * as loaderConstants from '../Constants';
import { ILoadingSuccess, ILoadingClear } from '../Actions/action.types';
import { initialState } from '../helpers/initialState';

export const loading = (
  initialLoadingState = initialState.loading,
  action: ILoadingSuccess | ILoadingClear,
): boolean => {
  switch (action.type) {
    case loaderConstants.LOADERSUCCESS:
      return action?.loading;
    case loaderConstants.LOADERCLEAR:
      return action?.loading;
    default:
      return initialLoadingState;
  }
};
