/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-file no-use-before-define
import * as React from 'react';

function SvgEvent(props) {
  return (
    <svg width={54} height={54} fill="currentColor" viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M48.41 5.858a4.812 4.812 0 00-.33-.003h-4.164V2.277C43.916.846 42.549 0 41.117 0h-3.839c-1.43 0-2.472.846-2.472 2.277v3.578H19.193V2.277C19.193.846 18.152 0 16.72 0h-3.838c-1.431 0-2.798.846-2.798 2.277v3.578H5.92a4.815 4.815 0 00-4.944 5.01V48.47C.976 51.333 3.058 54 5.92 54h42.16c2.862 0 4.944-2.667 4.944-5.53V10.865a4.814 4.814 0 00-4.613-5.007zM37.28 2.602h4.033V9.76H37.28V2.602zm-24.398 0h3.708V9.76h-3.708V2.602zm37.54 45.868c0 1.431-.911 2.928-2.342 2.928H5.92c-1.43 0-2.342-1.497-2.342-2.928V20.82h46.844v27.65zm0-37.605v7.352H3.578v-7.352A2.212 2.212 0 015.92 8.458h4.164v1.431c0 1.432 1.366 2.473 2.798 2.473h3.838a2.407 2.407 0 002.473-2.473V8.458h15.614v1.431a2.407 2.407 0 002.472 2.472h3.839c1.431 0 2.798-1.04 2.798-2.472V8.458h4.164a2.212 2.212 0 012.342 2.407z"
        fill="inherit"
      />
      <path
        d="M19.778 38.58l-1.17 6.507a1.301 1.301 0 001.886 1.366l5.855-3.058 5.856 3.058.585.13c.282.005.558-.087.78-.26.39-.284.59-.76.521-1.236l-1.17-6.506 4.749-4.554a1.43 1.43 0 00.325-1.367 1.366 1.366 0 00-1.041-.845l-6.506-.976-2.928-5.92a1.3 1.3 0 00-2.342 0l-2.928 5.92-6.506.976c-.47.08-.865.4-1.04.845-.138.481-.015 1 .325 1.367l4.75 4.554zm3.579-5.269c.408-.061.754-.333.91-.716l2.082-4.163 2.082 4.163c.156.383.502.655.91.716l4.62.65-3.318 3.189c-.309.306-.454.74-.39 1.17l.78 4.62-4.098-2.212-.586-.13-.585.13-4.1 2.212.781-4.62a1.366 1.366 0 00-.39-1.17l-3.318-3.188 4.62-.65z"
        fill="inherit"
      />
    </svg>
  );
}

export default SvgEvent;
