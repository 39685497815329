import React from 'react';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../Utils/routeConstant';

export default function Pricing(): JSX.Element {
  return (
    <>
      <div className="bg-white">
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-white uppercase rounded-full bg-brand-light">
                Just Do It
              </p>
            </div>
            <h2 className="max-w-lg mb-6 text-3xl font-extrabold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
              <span className="relative inline-block">
                <svg
                  viewBox="0 0 52 24"
                  fill="currentColor"
                  className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-gray-7 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block">
                  <defs>
                    <pattern id="e77df901-b9d7-4b9b-822e-16b2d410795b" x="0" y="0" width=".135" height=".30">
                      <circle cx="1" cy="1" r=".7" />
                    </pattern>
                  </defs>
                  <rect fill="url(#e77df901-b9d7-4b9b-822e-16b2d410795b)" width="52" height="24" />
                </svg>
                <span className="relative">We only earn</span>
              </span>{' '}
              when <span className="text-brand-light">you</span> earn
            </h2>
            <p className="text-xl text-gray-500">
              We charge only 5% of your earnings. No hidden fees. No extra cost at all to use Rehustle.
            </p>
          </div>
          <div className="grid max-w-md gap-10 row-gap-5 sm:row-gap-10 lg:max-w-sm sm:mx-auto">
            <div className="flex flex-col justify-between p-5 bg-white border rounded shadow-sm">
              <div className="mb-6">
                <div className="flex items-center justify-between pb-6 mb-6 border-b">
                  <div>
                    <p className="text-sm font-bold tracking-wider uppercase">For Creators</p>
                    <p className="text-5xl font-extrabold">Free</p>
                  </div>
                  <div className="flex items-center justify-center w-24 h-24 rounded-full bg-blue-gray-50">
                    <svg className="w-10 h-10 text-gray-600" viewBox="0 0 24 24" strokeLinecap="round">
                      <path
                        d="M12,7L12,7 c-1.657,0-3-1.343-3-3v0c0-1.657,1.343-3,3-3h0c1.657,0,3,1.343,3,3v0C15,5.657,13.657,7,12,7z"
                        fill="none"
                        stroke="currentColor"
                      />
                      <path
                        d="M15,23H9v-5H7v-6 c0-1.105,0.895-2,2-2h6c1.105,0,2,0.895,2,2v6h-2V23z"
                        fill="none"
                        stroke="currentColor"
                      />
                    </svg>
                  </div>
                </div>
                <div>
                  <p className="mb-2 font-bold tracking-wide hidden">Features</p>
                  <ul className="space-y-2">
                    <li className="flex items-center">
                      <div className="mr-2">
                        <svg className="w-4 h-4 text-deep-purple-accent-400" viewBox="0 0 24 24" strokeLinecap="round">
                          <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8" />
                          <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor" />
                        </svg>
                      </div>
                      <p className="font-medium text-gray-800">Single Profile Link</p>
                    </li>
                    <li className="flex items-center">
                      <div className="mr-2">
                        <svg className="w-4 h-4 text-deep-purple-accent-400" viewBox="0 0 24 24" strokeLinecap="round">
                          <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8" />
                          <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor" />
                        </svg>
                      </div>
                      <p className="font-medium text-gray-800">Unlimited Products and Services</p>
                    </li>
                    <li className="flex items-center">
                      <div className="mr-2">
                        <svg className="w-4 h-4 text-deep-purple-accent-400" viewBox="0 0 24 24" strokeLinecap="round">
                          <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8" />
                          <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor" />
                        </svg>
                      </div>
                      <p className="font-medium text-gray-800">Unlimited Links</p>
                    </li>
                    <li className="flex items-center">
                      <div className="mr-2">
                        <svg className="w-4 h-4 text-deep-purple-accent-400" viewBox="0 0 24 24" strokeLinecap="round">
                          <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8" />
                          <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor" />
                        </svg>
                      </div>
                      <p className="font-medium text-gray-800">Social Profile Links</p>
                    </li>
                    <li className="flex items-center">
                      <div className="mr-2">
                        <svg className="w-4 h-4 text-deep-purple-accent-400" viewBox="0 0 24 24" strokeLinecap="round">
                          <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8" />
                          <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor" />
                        </svg>
                      </div>
                      <p className="font-medium text-gray-800">Real time alerts on Email</p>
                    </li>
                    <li className="flex items-center">
                      <div className="mr-2">
                        <svg className="w-4 h-4 text-deep-purple-accent-400" viewBox="0 0 24 24" strokeLinecap="round">
                          <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8" />
                          <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor" />
                        </svg>
                      </div>
                      <p className="font-medium text-gray-800">Stats and Reports</p>
                    </li>
                    <li className="flex items-center">
                      <div className="mr-2">
                        <svg className="w-4 h-4 text-deep-purple-accent-400" viewBox="0 0 24 24" strokeLinecap="round">
                          <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8" />
                          <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor" />
                        </svg>
                      </div>
                      <p className="font-medium text-gray-800">Payouts in 48 hours</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <Link
                  to={APP_ROUTES.SIGNUP}
                  className="inline-flex items-center justify-center w-full h-12 px-6 mb-4 font-medium tracking-wide text-white transition duration-200 bg-brand-default rounded shadow-md hover:bg-brand-hover focus:shadow-outline focus:outline-none">
                  Start now
                </Link>
                <p className="text-sm text-gray-4">
                  Payouts will be processed once you provide your bank account details
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
