/* eslint-disable prefer-template */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { apiUrls } from '../Utils/apiUrls';
import { IActivateProduct, IUserDetails } from '../helpers/initialState';
import axiosInstance from '../Utils/interceptor';

export const activateService = {
  postActivateService,
  postPayment,
};

async function postActivateService(user: IUserDetails, id: string | undefined): Promise<void> {
  const { data } = await axiosInstance.post(`${apiUrls.POST_ACTIVATE_PRODUCT}/${id}`, user);
  return data.result;
}

function postPayment(result: IActivateProduct): any {
  post(result);
}

function isDate(val: any) {
  // Cross realm comptatible
  return Object.prototype.toString.call(val) === '[object Date]';
}
function isObj(val: any) {
  return typeof val === 'object';
}
function stringifyValue(val: any) {
  if (isObj(val) && !isDate(val)) {
    return JSON.stringify(val);
  } else {
    return val;
  }
}
function buildForm({ action, params }: any) {
  const form = document.createElement('form');
  form.setAttribute('method', 'post');
  form.setAttribute('action', action);
  Object.keys(params).forEach((key) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', key);
    input.setAttribute('value', stringifyValue(params[key]));
    form.appendChild(input);
  });
  return form;
}
function post(details: any) {
  const processConfig = details.processConfig;
  const txnInitUrl = details.txnInitUrl;
  const form = buildForm({ action: txnInitUrl, params: processConfig });
  document.body.appendChild(form);
  form.submit();
  form.remove();
}
