import * as userConstants from '../Constants';
import {
  ILoginRequest,
  ILoginSuccess,
  ILoginFail,
  ILogout,
  IRegisterFailure,
  IRegisterRequest,
  IRegisterSuccess,
  IPostGoogleSignInFailure,
  IPostGoogleSignInSuccess,
  IPostGoogleSignInRequest,
  IRefresh,
} from '../Actions/action.types';
import { initialState, IUser } from '../helpers/initialState';

export const user = (
  userState = initialState.user,
  action:
    | ILoginRequest
    | ILoginSuccess
    | ILoginFail
    | ILogout
    | IRegisterRequest
    | IRegisterFailure
    | IRegisterSuccess
    | IRefresh
    | IPostGoogleSignInFailure
    | IPostGoogleSignInRequest
    | IPostGoogleSignInSuccess,
): IUser => {
  switch (action.type) {
    case userConstants.REFRESH:
      return { ...initialState.user };
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        email: action.user.email,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: false,
        email: action.user.email,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        loggedIn: false,
        loginError: action.error?.message,
      };
    case userConstants.LOGOUT:
      return {};
    case userConstants.REGISTER_REQUEST || userConstants.POST_GOOGLE_SIGNIN_REQUEST:
      return {
        registering: true,
        email: action.user.email,
      };
    case userConstants.REGISTER_SUCCESS || userConstants.POST_GOOGLE_SIGNIN_SUCCESS:
      return { ...userState, registering: false };
    case userConstants.REGISTER_FAILURE || userConstants.POST_GOOGLE_SIGNIN_FAILURE:
      return { registering: false, signinError: action.error.message };
    default:
      return { ...userState };
  }
};
