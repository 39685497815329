/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import validator from 'validator';
import { useDispatch } from 'react-redux';
import { activateServiceActions } from '../Actions';
import { Close } from '../../assets';
interface IPaymentInfoPopup {
  handleFormPopupClose?: (event: React.SyntheticEvent) => void;
  selectedProduct?: string;
  username?: string;
}
function PaymentInfoPopup(props: IPaymentInfoPopup): JSX.Element {
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    contact: '',
    email: '',
  });
  const [validation, setValidation] = useState({
    contact: true,
    email: true,
  });
  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value } = e.target;
    setUser((user) => ({ ...user, [name]: value }));
  }

  function handleValidation(e: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value } = e.target;
    if (name === 'contact') {
      setValidation({ ...validation, [name]: !!value.match(/^(\+\d{1,3}[- ]?)?\d{10}$/) });
    }
    if (name === 'email') {
      setValidation({ ...validation, [name]: validator.isEmail(value) });
    }
  }

  const [submitted, setSubmitted] = useState(false);

  function handleSubmit(e: React.SyntheticEvent): void {
    e.preventDefault();

    setSubmitted(true);
    if (user.email && user.contact && validation.contact && validation.email) {
      setSubmitted(true);
      dispatch(activateServiceActions.postActivateService(user, props.username, props.selectedProduct));
    }
  }
  return (
    <>
      <div className="justify-center w-full items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 mx-auto max-w-xs w-96">
          {/*content*/}
          <div className="border-0 rounded-2xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="absolute rounded top-0 z-10 w-full px-3 py-3 flex justify-between items-center">
              <div className="font-bold text-lg ml-3">Information</div>
              <div className="flex items-center mr-3">
                <button
                  onClick={(e) => props.handleFormPopupClose && props.handleFormPopupClose(e)}
                  className="w-full text-gray-3 rounded-full hover:bg-gray-100 hover:text-black  p-1 focus:outline-none">
                  <Close />
                </button>
              </div>
            </div>
            {/*body*/}
            <div className="h-80  false mt-14">
              <div className="p-6">
                <div className="mb-5">
                  <label className="flex text-xs font-bold tracking-tight text-gray-1">
                    <div className="flex flex-1">Email</div>
                  </label>
                  <div className="flex rounded mt-2">
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter your Email Address"
                      required={true}
                      onChange={handleChange}
                      onBlur={handleValidation}
                      className="flex-1 form-input block w-full min-w-0 rounded transition duration-150 ease-in-out p-2 border focus:outline-none  sm:text-sm sm:leading-5 disabled:bg-gray-100 text-black disabled:text-gray-3"
                    />
                  </div>
                  {user.email && !validation.email && (
                    <div className="p-0.5 text-xs text-brand-error">Please provide a valid Email Address</div>
                  )}
                  {submitted && !user.email && <div className="p-0.5 text-xs text-brand-error">Email is required</div>}
                </div>
                <div className="mb-5">
                  <label className="flex text-xs font-bold tracking-tight text-gray-1">
                    <div className="flex flex-1">Phone No.</div>
                  </label>
                  <div className="relative flex flex-col mt-2">
                    <input
                      type="tel"
                      name="contact"
                      placeholder="Enter your Phone No."
                      required={true}
                      onChange={handleChange}
                      onBlur={handleValidation}
                      className="flex-1 form-input block w-full min-w-0 rounded transition duration-150 ease-in-out sm:text-sm sm:leading-5 disabled:bg-gray-100 p-2 border focus:outline-none text-black disabled:text-gray-400  "
                    />
                  </div>
                  {user.contact && !validation.contact && (
                    <div className="p-0.5 text-xs text-brand-error">Please provide a valid Number</div>
                  )}
                  {submitted && !user.contact && (
                    <div className="p-0.5 text-xs text-brand-error">Phone no. is required</div>
                  )}
                </div>
                <div className="flex w-full xs:mt-6">
                  <button
                    type="submit"
                    onClick={(e) => handleSubmit(e)}
                    className="py-2 px-4  bg-brand-default hover:bg-brand-default focus:ring-brand-default focus:ring-offset-brand-default text-white w-full duration-150 disabled:cursor-default rounded-lg transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-1 focus:ring-offset-2">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-40 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}

export default PaymentInfoPopup;
