/* eslint-disable @typescript-eslint/no-explicit-any */
import { apiUrls } from '../Utils/apiUrls';
import { IUser } from '../helpers/initialState';
import { history } from '../helpers';
import { APP_ROUTES } from '../Utils/routeConstant';
import axiosInstance from '../Utils/interceptor';

/**
 *
 * @param email
 * @param password
 * @returns token and reroute user to a specific location
 */
const login = async (email: string, password: string): Promise<void> => {
  const { data } = await axiosInstance.post(apiUrls.POST_LOGIN, { email, password });
  //localStorage.setItem('token', JSON.stringify(data.result.token));
  return data.result;
};

/**
 *
 * @param user
 * @returns api data and set token
 */
const register = async (user: IUser): Promise<void> => {
  const { data } = await axiosInstance.post(apiUrls.POST_SIGN_UP, { ...user });
  return data.result;
};

const resetPassword = async (email: string): Promise<void> => {
  const { data } = await axiosInstance.post(apiUrls.POST_RESET_PASSWORD, { email });
  return data.result;
};

const verifyResetPasswordToken = async (token: string): Promise<void> => {
  const { data } = await axiosInstance.get(`${apiUrls.GET_VERIFY_TOKEN}${token}`);
  return data.result;
};

const updateNewPassword = async (password: string, token: string): Promise<void> => {
  const { data } = await axiosInstance.post(apiUrls.POST_UPDATE_PASSWORD, { password, token });
  return data.result;
};

const googleSignIn = async (accessToken: string): Promise<void> => {
  const request = { access_token: accessToken };
  const { data } = await axiosInstance.post(apiUrls.POST_GOOGLE_SIGNIN, request);
  return data.result;
};

const googleSignUp = async (accessToken: string, userName: string): Promise<void> => {
  const request = { access_token: accessToken, userName };
  const { data } = await axiosInstance.post(apiUrls.POST_GOOGLE_SIGNUP, request);
  return data.result;
};
/** remove token from localstorage */
const logout = (): void => {
  localStorage.removeItem('token');
  history.push(APP_ROUTES.MAIN);
  window.location.reload();
};

export const authService = {
  login,
  logout,
  register,
  googleSignIn,
  resetPassword,
  updateNewPassword,
  googleSignUp,
  verifyResetPasswordToken,
};
