import React from 'react';
import { Chat, Clock, Event, Mobile } from '../../../assets';
export default function ServicesInfo(): JSX.Element {
  return (
    <>
      <div className="bg-gray-10">
        <div className="px-4 py-16 mx-auto sm:max-w-lg md:max-w-screen-lg lg:max-w-screen-lg md:px-24 lg:px-8 lg:py-20 mx-auto">
          <div className="grid gap-12 row-gap-8">
            <div className="flex flex-col justify-center">
              <div className="max-w-xl mb-6 mx-auto">
                <h2 className="max-w-lg mb-6 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-none text-center">
                  Services you can add
                </h2>
                <p className="mt-4 max-w-2xl text-xl text-gray-500 mx-auto text-center">
                  Add the following type of services on your profile and get paid
                </p>
              </div>
              <div className="grid gap-8 row-gap-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 mt-4">
                <div className="duration-300 transform bg-white border-l-4 border-brand-default hover:-translate-y-2">
                  <div className="h-full p-5 border border-l-0 rounded-r shadow-sm">
                    <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-brand-lighter">
                      <Event className={`w-6 h-6 fill-current text-brand-default group-hover:text-brand-hover`} />
                    </div>
                    <h6 className="mb-2 font-semibold leading-5 text-lg">Organise an event or webinar</h6>
                    <p className="text-sm text-gray-500">
                      Best for Webinars, Discussions, Virtual events, Masterclasses, Workshops
                    </p>
                  </div>
                </div>
                <div className="duration-300 transform bg-white border-l-4 border-brand-default hover:-translate-y-2">
                  <div className="h-full p-5 border border-l-0 rounded-r shadow-sm">
                    <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-brand-lighter">
                      <Mobile className={`w-6 h-6 fill-current text-brand-default group-hover:text-brand-hover`} />
                    </div>
                    <h6 className="mb-2 font-semibold leading-5 text-lg">Sell Digital Product</h6>
                    <p className="text-sm text-gray-500">
                      Best for Ebooks, Downloadable Content, Recorded media or Digital files
                    </p>
                  </div>
                </div>
                <div className="duration-300 transform bg-white border-l-4 border-brand-default hover:-translate-y-2">
                  <div className="h-full p-5 border border-l-0 rounded-r shadow-sm">
                    <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-brand-lighter">
                      <Clock className={`w-6 h-6 fill-current text-brand-default group-hover:text-brand-hover`} />
                    </div>
                    <h6 className="mb-2 font-semibold leading-5 text-lg">Sell your time</h6>
                    <p className="text-sm text-gray-500">
                      Best for Consulting, Coaching, Mentoring, Trainings, 1-on-1s
                    </p>
                  </div>
                </div>
                <div className="duration-300 transform bg-white border-l-4 border-brand-default hover:-translate-y-2">
                  <div className="h-full p-5 border border-l-0 rounded-r shadow-sm">
                    <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-brand-lighter">
                      <Chat className={`w-6 h-6 fill-current text-brand-default group-hover:text-brand-hover`} />
                    </div>
                    <h6 className="mb-2 font-semibold leading-5 text-lg">Chat Services</h6>
                    <p className="text-sm text-gray-500">
                      Best for Guidance, Casual services, Community payments, Support services
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden">
              <img className="object-contain w-full h-56 sm:h-96" src="img/rehustle-services.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
