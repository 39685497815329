/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import * as publicProfileConatants from '../Constants';
import { publicProfileService } from '../Services';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IGetPublicProfileRequest, IGetPublicProfileSuccess, IGetPublicProfileFailure } from './action.types';
import { IPublicProfile } from '../helpers/initialState';

export const getPublicProfileRequest = (): IGetPublicProfileRequest => {
  return { type: publicProfileConatants.GET_PUBLIC_PROFILE_REQUEST, loading: false };
};

export const getPublicProfileSuccess = (username: IPublicProfile): IGetPublicProfileSuccess => {
  return { type: publicProfileConatants.GET_PUBLIC_PROFILE_SUCCESS, username };
};

export const getPublicProfileFailure = (error: string): IGetPublicProfileFailure => {
  return { type: publicProfileConatants.GET_PUBLIC_PROFILE_FAILURE, error };
};

export const getPublicProfile = (username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(getPublicProfileRequest());

    await publicProfileService
      .getPublicProfile(username)
      .then((response: any) => dispatch(getPublicProfileSuccess(response)))
      .catch((error) => dispatch(getPublicProfileFailure(error?.data?.message)));
  };
};

export const publicProfileActions = {
  getPublicProfile,
};
