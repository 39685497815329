export interface StringMap {
  [key: string]: string;
}
const domain = process.env.REACT_APP_INDEHUSTLE_API_DOMAIN || '';

export const apiUrls: StringMap = {
  POST_SIGN_UP: `${domain}/v1/auth/signup`,
  GET_CURRENT_USER: `${domain}/v1/user/currentUser`,
  PUT_UPDATE_USER_PROFILE: `${domain}/v1/user/update`,
  POST_ADD_NEW_PRODUCT: `${domain}/v1/p/product`,
  GET_ALL_PRODUCT: `${domain}/v1/p/products`,
  PUT_UPDATE_PRODUCT: `${domain}/v1/p/product`,
  POST_LOGIN: `${domain}/v1/auth/signin`,
  POST_ACTIVATE_PRODUCT: `${domain}/v1/public/product/activate`,
  GET_PUBLIC_PROFILE: `${domain}/v1/public/user`,
  GET_UPLOAD_MEDIA: `${domain}/v1/p/mediaUrl`,
  POST_RESET_PASSWORD: `${domain}/v1/auth/resetPassword`,
  GET_VERIFY_TOKEN: `${domain}/verifyResetToken?token=`,
  POST_UPDATE_PASSWORD: `${domain}/v1/auth/updatePassword`,
  POST_GOOGLE_SIGNUP: `${domain}/v1/auth/social/g/signup`,
  POST_GOOGLE_SIGNIN: `${domain}/v1/auth/social/g/signin`,
  GET_TWITTER_USER: `${domain}/v1/social/p`,
  GET_INSIGHTS: `${domain}/v1/user/insights`,
  POST_BANK_DETAILS: `${domain}/v1/bank/details/update`,
};
