import React from 'react';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../Utils/routeConstant';
export default function Footer(): JSX.Element {
  return (
    <>
      <footer className="relative bg-white pt-12 pb-2 w-full">
        <div className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
          <div className="grid gap-8 md:gap-16 row-gap-10 mb-8 lg:grid-cols-6">
            <div className="md:max-w-md lg:col-span-2">
              <a href="/" aria-label="Go home" title="Company" className="inline-flex items-center">
                <svg
                  className="w-8 text-deep-purple-accent-400 hidden"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <circle cx="24" cy="24" r="24" fill="#7A38FF" />
                  <path
                    d="M31.6667 31.5281C31.8889 31.8652 32 32.1929 32 32.5112C32 32.9419 31.8241 33.2978 31.4722 33.5787C31.1389 33.8596 30.7407 34 30.2778 34C29.9815 34 29.6944 33.9345 29.4167 33.8034C29.1574 33.6536 28.9352 33.4382 28.75 33.1573L25.0556 27.4831C24.6852 26.8839 24.287 26.4625 23.8611 26.2191C23.4537 25.9757 22.9167 25.8539 22.25 25.8539H19.5278V32.1461C19.5278 32.7079 19.3704 33.1573 19.0556 33.4944C18.7407 33.8127 18.3148 33.9719 17.7778 33.9719C17.2407 33.9719 16.8056 33.8127 16.4722 33.4944C16.1574 33.1573 16 32.7079 16 32.1461V15.7135C16 15.1704 16.1481 14.7491 16.4444 14.4494C16.7593 14.1498 17.1852 14 17.7222 14H24.6667C26.8889 14 28.5741 14.5056 29.7222 15.5169C30.8889 16.5094 31.4722 17.9513 31.4722 19.8427C31.4722 21.3783 31.037 22.6423 30.1667 23.6348C29.3148 24.6086 28.1019 25.236 26.5278 25.5169C27.0648 25.6479 27.537 25.882 27.9444 26.2191C28.3519 26.5562 28.75 27.0243 29.1389 27.6236L31.6667 31.5281ZM24.1667 23.073C25.5185 23.073 26.5093 22.8202 27.1389 22.3146C27.7685 21.7903 28.0833 20.9944 28.0833 19.927C28.0833 18.8408 27.7685 18.0543 27.1389 17.5674C26.5093 17.0618 25.5185 16.809 24.1667 16.809H19.5V23.073H24.1667Z"
                    fill="white"
                  />
                </svg>

                <span className="text-xl font-bold tracking-wide text-brand-default">ReHustle</span>
              </a>
              <div className="mt-4 lg:max-w-sm">
                <p className="text-sm text-gray-500">
                  Set up your digital professional page in minutes and start selling events, workshops, masterclasses,
                  AMAs or digital products and leave rest to us
                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-5 row-gap-8 lg:col-span-4 md:grid-cols-4">
              <div className="md:col-end-4">
                <p className="font-semibold tracking-wide text-gray-800">Resources</p>
                <ul className="mt-2 space-y-2">
                  <li>
                    <a
                      href="/"
                      className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400">
                      Help Center
                    </a>
                  </li>
                  <li>
                    <a
                      href="/"
                      className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400">
                      Blog
                    </a>
                  </li>
                  <li>
                    <a
                      href="/"
                      className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400">
                      Templates
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <p className="font-semibold tracking-wide text-gray-800">Company</p>
                <ul className="mt-2 space-y-2">
                  <li>
                    <Link
                      to={APP_ROUTES.ABOUTUS}
                      className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400">
                      About us
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={APP_ROUTES.CONTACTUS}
                      className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400">
                      Contact us
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={APP_ROUTES.PRIVACYPOLICY}
                      className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={APP_ROUTES.TERMSANDCONDITIONS}
                      className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400">
                      Terms & Conditions
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={APP_ROUTES.REFUNDS}
                      className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400">
                      Refunds
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between pt-5 pb-5 border-t sm:flex-row">
            <p className="text-sm text-gray-600">
              Copyright © {new Date().getFullYear()} Rehustle. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}
