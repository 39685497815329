export const toastOptions = {
  success: {
    style: {
      background: '#F3FCF4',
      border: '1px solid #4FD564',
      borderRadius: '4px',
    },
  },
  error: {
    style: {
      background: '#FDF6F6',
      border: '1px solid #EC4140',
      borderRadius: '4px',
    },
  },
};
