import React from 'react';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../Utils/routeConstant';
function Callaction(): JSX.Element {
  return (
    <>
      <div className="bg-gray-10">
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
            <div className="flex flex-col mb-10 sm:text-center sm:mb-0">
              <a href="/" className="mb-6 mx-auto">
                <div className="flex items-center justify-center w-12 h-12 rounded-full bg-brand-lighter">
                  <svg className="w-10 h-10 text-brand-default" stroke="currentColor" viewBox="0 0 52 52">
                    <polygon
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </div>
              </a>
              <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-8">
                <h2 className="max-w-lg text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                  <span className="relative inline-block">
                    <svg
                      viewBox="0 0 52 24"
                      fill="currentColor"
                      className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-gray-7 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block">
                      <defs>
                        <pattern id="e77df901-b9d7-4b9b-822e-16b2d410795b" x="0" y="0" width=".135" height=".30">
                          <circle cx="1" cy="1" r=".7" />
                        </pattern>
                      </defs>
                      <rect fill="url(#e77df901-b9d7-4b9b-822e-16b2d410795b)" width="52" height="24" />
                    </svg>
                    <span className="relative">Get</span>
                  </span>{' '}
                  started now for free
                </h2>
                <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                  Show the world who you are and earn money using Paytm from your skills and content
                </p>
              </div>
              <div className="sm:flex sm:justify-center lg:justify-center">
                <div className="rounded-md shadow">
                  <Link
                    to={APP_ROUTES.SIGNUP}
                    className="w-full flex items-center justify-center px-6 py-3 border border-transparent font-medium rounded-md text-white bg-brand-default hover:brand-hover md:py-3 md:px-10">
                    Start now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Callaction;
