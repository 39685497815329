/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-file no-use-before-define
import * as React from 'react';

function SvgCalendar(props) {
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.083 1.667a.75.75 0 00-1.5 0v.916H7.417v-.916a.75.75 0 10-1.5 0v.916h-1.75A2.417 2.417 0 001.75 5v11.667a2.417 2.417 0 002.417 2.416h11.666a2.417 2.417 0 002.417-2.416V5a2.417 2.417 0 00-2.417-2.417h-1.75v-.916zm2.667 5.916V5a.917.917 0 00-.917-.917h-1.75V5a.75.75 0 01-1.5 0v-.917H7.417V5a.75.75 0 01-1.5 0v-.917h-1.75A.917.917 0 003.25 5v2.583h13.5zm-13.5 1.5h13.5v7.584c0 .506-.41.916-.917.916H4.167a.917.917 0 01-.917-.916V9.083z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgCalendar;
