/* eslint-disable @typescript-eslint/ban-types */
import * as productConstants from '../Constants';
import {
  IGetProductRequest,
  IGetProductFailure,
  IGetProductSuccess,
  IAddProductFailure,
  IAddProductRequest,
  IAddProductSuccess,
  IUpdateProductFailure,
  IUpdateProductRequest,
  IUpdateProductSuccess,
  IDeleteProductFailure,
  IDeleteProductRequest,
  IDeleteProductSuccess,
} from '../Actions/action.types';
import { initialState, IProduct } from '../helpers/initialState';

export const products = (
  productState = initialState.products,
  action:
    | IGetProductRequest
    | IGetProductFailure
    | IGetProductSuccess
    | IAddProductFailure
    | IAddProductRequest
    | IAddProductSuccess
    | IUpdateProductFailure
    | IUpdateProductRequest
    | IUpdateProductSuccess
    | IDeleteProductFailure
    | IDeleteProductRequest
    | IDeleteProductSuccess,
): IProduct[] | [] => {
  switch (action.type) {
    case productConstants.GET_PRODUCT_REQUEST:
      return productState;
    case productConstants.GET_PRODUCT_SUCCESS:
      return [...action.response];
    case productConstants.GET_PRODUCT_FAILURE:
      return productState;
    case productConstants.ADD_PRODUCT_REQUEST:
      return productState;
    case productConstants.ADD_PRODUCT_SUCCESS:
      return [action.product, ...productState];
    case productConstants.ADD_PRODUCT_FAILURE:
      return productState;
    case productConstants.POST_UPDATE_PRODUCT_REQUEST:
      return productState;
    case productConstants.POST_UPDATE_PRODUCT_SUCCESS: {
      const updatedProduct = productState.findIndex((product) => product._id == action.product._id);
      const copyproduct = [...productState];
      copyproduct[updatedProduct] = {
        ...copyproduct[updatedProduct],
        ...action.product,
      };
      return copyproduct;
    }
    case productConstants.POST_UPDATE_PRODUCT_FAILURE:
      return productState;
    case productConstants.DELETE_PRODUCT_REQUEST:
      return productState;
    case productConstants.DELETE_PRODUCT_SUCCESS: {
      const updatedProducts = productState.filter((product) => product._id !== action.product._id);
      return updatedProducts;
    }
    case productConstants.DELETE_PRODUCT_FAILURE:
      return productState;
    default:
      return productState;
  }
};
