/* eslint-disable @typescript-eslint/no-explicit-any */
import { apiUrls } from '../Utils/apiUrls';
import axiosInstance from '../Utils/interceptor';
import axios from 'axios';

export const uploadMediaService = {
  getUploadMedia,
  postUploadMedia,
};

async function getUploadMedia(mType: string, cType: string): Promise<void> {
  const { data } = await axiosInstance.get(`${apiUrls.GET_UPLOAD_MEDIA}?mType=${mType}&cType=${cType}`);
  return data.result;
}

async function postUploadMedia(url: string, file: File): Promise<void> {
  const { data } = await axios({
    method: 'put',
    url,
    data: file,
    headers: new Headers({ 'Content-Type': file.type }),
  });
  return data;
}
