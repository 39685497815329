import React, { useState } from 'react';
import { ILink } from '../helpers/initialState';

const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

interface IStyles {
  color?: string;
  bannerImage?: string;
  profileImage?: string;
  buttonRounded?: string;
}
interface ILinkProps extends ILink {
  appliedStyles?: IStyles;
}

const LinkSection = (props: ILinkProps): JSX.Element => {
  const { title, url, appliedStyles } = props;
  const [copySuccess, setCopySuccess] = useState('Copy Link');
  const handleCopy = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    navigator.clipboard.writeText(url);
    setCopySuccess('Copied');
    setTimeout(() => {
      setCopySuccess('Copy Link');
    }, 1000);
  };
  return (
    <section className="relative">
      <div className="container mx-auto">
        <div
          className={`relative flex break-words bg-white w-full mb-6 shadow p-6 ${
            appliedStyles?.buttonRounded ? appliedStyles?.buttonRounded : 'rounded-xl'
          }`}>
          <div
            onClick={() => openInNewTab(url)}
            className="relative flex break-words bg-white w-4/5 space-x-2 focus:outline-none cursor-pointer">
            <div className="flex items-center justify-center focus:outline-none">
              <div
                className="bg-brand-default w-10 h-10 flex justify-center items-center rounded"
                style={appliedStyles?.color ? { background: appliedStyles?.color } : {}}>
                <img src={`/img/link.svg`} className="w-8 h-8" />
              </div>
            </div>
            <div className="flex flex-1 flex-col justfy-center">
              <div className="p-2">
                <h2 className="text-sm font-bold" style={appliedStyles?.color ? { color: appliedStyles?.color } : {}}>
                  {title}
                </h2>
                <p className="text-xs font-medium opacity-50 break-all w-100">{url}</p>
              </div>
            </div>
          </div>
          <div className="w-1/5 flex items-center justify-center">
            <button
              onClick={(event) => handleCopy(event)}
              className="text-sm text-brand-default w-24 h-10 rounded focus:text-brand-hover hover:text-brand-hover focus:outline-none"
              style={appliedStyles?.color ? { color: appliedStyles?.color } : {}}>
              {copySuccess}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LinkSection;
