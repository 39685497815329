/* eslint-disable @typescript-eslint/no-explicit-any */
import * as productConstants from '../Constants';
import { ISelectProduct } from '../Actions/action.types';
import { initialState } from '../helpers/initialState';

export const currentProduct = (productState = initialState.currentProduct, action: ISelectProduct): any => {
  switch (action.type) {
    case productConstants.SELECT_PRODUCT_SUCCESS:
      return {
        serviceType: action.product?.serviceType,
        title: action.product.title,
        mode: action.product.mode,
        inputProduct: action.product?.inputProduct,
      };
    default:
      return productState;
  }
};
