/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import {
  IPostActivateServiceFailure,
  IPostActivateServiceRequest,
  IPostActivateServiceSuccess,
} from '../Actions/action.types';
import { initialState, IActivateProduct } from '../helpers/initialState';
import * as activateServiceConstants from '../Constants';

export const activateService = (
  activateServiceState = initialState?.activateService,
  action: IPostActivateServiceFailure | IPostActivateServiceRequest | IPostActivateServiceSuccess,
): IActivateProduct | {} => {
  switch (action.type) {
    case activateServiceConstants.POST_ACTIVATE_REQUEST:
      return {};
    case activateServiceConstants.POST_ACTIVATE_SUCCESS:
      return { ...activateServiceState, ...action.userDetailsResponse };
    case activateServiceConstants.POST_ACTIVATE_FAILURE:
      return {};
    default:
      return {};
  }
};
