/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import * as productConstants from '../Constants';
import { productService } from '../Services';
import { alertActions } from './alert.actions';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  SelectedProduct,
  ISelectProduct,
  IAddProductRequest,
  IAddProductSuccess,
  IAddProductFailure,
  IGetProductRequest,
  IGetProductSuccess,
  IGetProductFailure,
  IUpdateProductRequest,
  IUpdateProductSuccess,
  IUpdateProductFailure,
  IDeleteProductFailure,
  IDeleteProductSuccess,
  IDeleteProductRequest,
} from './action.types';
import { IProduct } from '../helpers/initialState';
import { loaderActions } from './loader.action';
import toast from 'react-hot-toast';

export const selectProduct = (product: SelectedProduct): ISelectProduct => {
  return { type: productConstants.SELECT_PRODUCT_SUCCESS, product };
};

export const addProductRequest = (product: IProduct): IAddProductRequest => {
  return { type: productConstants.ADD_PRODUCT_REQUEST, product };
};

export const addProductSuccess = (product: IProduct): IAddProductSuccess => {
  return { type: productConstants.ADD_PRODUCT_SUCCESS, product };
};

export const addProductFailure = (error: string): IAddProductFailure => {
  return { type: productConstants.ADD_PRODUCT_FAILURE, error };
};

export const getProductRequest = (): IGetProductRequest => {
  return { type: productConstants.GET_PRODUCT_REQUEST };
};

export const getProductSuccess = (response: any): IGetProductSuccess => {
  return { type: productConstants.GET_PRODUCT_SUCCESS, response };
};

export const getProductFailure = (error: string): IGetProductFailure => {
  return { type: productConstants.GET_PRODUCT_FAILURE, error };
};

export const postProductUpadtesRequest = (product: IProduct): IUpdateProductRequest => {
  return { type: productConstants.POST_UPDATE_PRODUCT_REQUEST, product };
};

export const postProductUpadtesSuccess = (product: IProduct): IUpdateProductSuccess => {
  return { type: productConstants.POST_UPDATE_PRODUCT_SUCCESS, product };
};

export const postProductUpadtesFailure = (error: string): IUpdateProductFailure => {
  return { type: productConstants.POST_UPDATE_PRODUCT_FAILURE, error };
};

export const deleteProductRequest = (product: IProduct): IDeleteProductRequest => {
  return { type: productConstants.DELETE_PRODUCT_REQUEST, product };
};

export const deleteProductSuccess = (product: IProduct): IDeleteProductSuccess => {
  return { type: productConstants.DELETE_PRODUCT_SUCCESS, product };
};

export const deleteProductFailure = (error: string): IDeleteProductFailure => {
  return { type: productConstants.DELETE_PRODUCT_FAILURE, error };
};
/**
 *
 * @param product
 * @returns post the added product and update store
 */
export const addProduct = (product: any): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    const loadingToastId = toast.loading('Adding');
    await dispatch(addProductRequest(product));
    productService
      .addProduct(product)
      .then(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (product: any) => {
          dispatch(addProductSuccess(product));
          toast.dismiss(loadingToastId);
          toast.success('Added Successfully');
        },
      )
      .catch((error) => {
        dispatch(addProductFailure(error?.data?.message));
        dispatch(alertActions.error(error?.data?.message));
        toast.dismiss(loadingToastId);
        toast.error('Some Error Occurred');
      });
  };
};

export const addBulkProduct = (products: any): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(addProductRequest(products));
    const loadingToastId = toast.loading('Adding');
    Promise.all(
      products.map((product: IProduct): any => {
        productService
          .addProduct(product)
          .then(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (product: any) => {
              dispatch(addProductSuccess(product));
            },
          )
          .catch((error) => {
            dispatch(addProductFailure(error?.data?.message));
            dispatch(alertActions.error(error?.data?.message));
            toast.error('Some Error Occurred');
          });
      }),
    ).then(() => {
      toast.dismiss(loadingToastId);
      toast.success(`${products.length} Products Added Successfully`);
    });

    //productFailureCount && toast.error(`Some Error Occurred in adding ${productFailureCount} products`);
  };
};

export const updateProduct = (product: IProduct): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    const loadingToastId = toast.loading('Updating');

    await dispatch(postProductUpadtesRequest(product));

    await productService
      .updateProduct(product)
      .then((product: any) => {
        dispatch(postProductUpadtesSuccess(product));
        toast.dismiss(loadingToastId);
        toast.success('Updated Successfully');
      })
      .catch((error) => {
        dispatch(postProductUpadtesFailure(error?.data?.message));
        dispatch(alertActions.error(error?.data?.message));
        toast.dismiss(loadingToastId);
        toast.error('Some Error Occurred');
      });
  };
};

export const deleteProduct = (product: IProduct): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(deleteProductRequest(product));
    const loadingToastId = toast.loading('Deleting');
    dispatch(loaderActions.addLoader());
    productService
      .deleteProduct(product)
      .then(() => {
        dispatch(deleteProductSuccess(product));
        toast.dismiss(loadingToastId);
        toast.success('Deleted Successfully');
      })
      .catch((error) => {
        dispatch(deleteProductFailure(error?.data?.message));
        toast.dismiss(loadingToastId);
        toast.error('Some Error Occurred');
      });
    dispatch(loaderActions.removeLoader());
  };
};

export const getProducts = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(loaderActions.addLoader());
    await dispatch(getProductRequest());

    await productService
      .getAllProducts()
      .then((response: any) => dispatch(getProductSuccess(response)))
      .catch((error) => dispatch(getProductFailure(error?.data?.message)));
    dispatch(loaderActions.removeLoader());
  };
};

export const productActions = {
  selectProduct,
  getProducts,
  addProduct,
  addBulkProduct,
  updateProduct,
  deleteProduct,
};
