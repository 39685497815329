/*eslint no-duplicate-case: "error"*/
import * as userProfileConstants from '../Constants';
import { IGetInsightsFailure, IGetInsightsRequest, IGetInsightsSuccess } from '../Actions/action.types';
import { initialState, IUserProfile } from '../helpers/initialState';

const transformState = (
  prevstate: IUserProfile | undefined,
  response: IUserProfile | undefined,
): IUserProfile | undefined => {
  const newResponse = prevstate && { ...prevstate, ...response };
  const appearence = prevstate?.appearance && JSON.parse(prevstate?.appearance);
  if (newResponse && prevstate && response && response.profileImage) {
    newResponse.appearance = JSON.stringify({ ...appearence, profileImage: response.profileImage });
  }

  return newResponse || prevstate;
};

export const insights = (
  state = initialState?.insights,
  action: IGetInsightsFailure | IGetInsightsRequest | IGetInsightsSuccess,

  // eslint-disable-next-line @typescript-eslint/ban-types
): IUserProfile | undefined | {} => {
  switch (action.type) {
    case userProfileConstants.GET_INSIGHTS_REQUEST:
      return { ...state };
    case userProfileConstants.GET_INSIGHTS_SUCCESS:
      return transformState(state, action.response) ? { ...transformState(state, action.response) } : { ...state };
    case userProfileConstants.GET_INSIGHTS_FAILURE:
      return { ...state };
    default:
      return state;
  }
};
