import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import Callaction from '../Template/components/Callaction';
import Cta2 from '../Template/components/Cta2';
import Faqs from '../Template/components/Faqs';
import Footer from '../Template/components/Footer';
import Pricing from '../Template/components/Pricing';
import ServicesInfo from '../Template/components/ServicesInfo';
import Showcase from '../Template/components/Showcase';
import { APP_ROUTES } from '../Utils/routeConstant';

const LandingPage: React.FC = () => {
  const history = useHistory();

  const [mobileMenuHidden, setMobileMenuHidden] = useState(true);

  const handleHideMobileMenu = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    setMobileMenuHidden(true);
  };

  const handleShowMobileMenu = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    setMobileMenuHidden(false);
  };

  const gettingStarted = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    history.push(APP_ROUTES.SIGNUP);
  };

  const login = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    history.push(APP_ROUTES.LOGIN);
  };
  return (
    <article>
      <Helmet>
        <title>ReHustle - Sell and Grow with ease</title>
        <meta name="description" content="Homepage" />
      </Helmet>
      <>
        <div className="relative bg-white overflow-hidden">
          <div className="max-w-7xl mx-auto ">
            <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
              <svg
                className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2 h-screen"
                fill="currentColor"
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
                aria-hidden="true">
                <polygon points="50,0 100,0 50,100 0,100" />
              </svg>

              <div className="relative py-6 px-4 sm:px-6 lg:px-8">
                <nav
                  className="relative flex items-center justify-between sm:h-10 lg:justify-start"
                  aria-label="Global">
                  <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                    <div className="flex items-center justify-between w-full md:w-auto">
                      <a href="/" className="text-brand-default font-black text-2xl">
                        <span className="sr-only">ReHustle</span>
                        <span>ReHustle</span>
                      </a>
                      <div className="-mr-2 flex items-center md:hidden">
                        <button
                          type="button"
                          onClick={(e) => handleShowMobileMenu(e)}
                          className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-inset focus:ring-brand-default"
                          aria-expanded="false">
                          <span className="sr-only">Open main menu</span>

                          <svg
                            className="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true">
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M4 6h16M4 12h16M4 18h16"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
                    <a href="#" className="font-medium text-gray-500 hover:text-gray-900 hidden">
                      How it works
                    </a>

                    <a href="#" className="font-medium text-gray-500 hover:text-gray-900 hidden">
                      Services
                    </a>

                    <a href="#" className="font-medium text-gray-500 hover:text-gray-900 hidden">
                      Example
                    </a>

                    <a
                      href="#"
                      onClick={(e) => login(e)}
                      className="font-medium text-brand-default hover:text-brand-hover">
                      Login
                    </a>

                    <a
                      href="#"
                      onClick={(e) => gettingStarted(e)}
                      className="font-medium text-brand-default hover:text-brand-hover">
                      Getting Started
                    </a>
                  </div>
                </nav>
              </div>

              <div
                className={`absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden ${
                  mobileMenuHidden ? 'hidden' : ''
                }`}>
                <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="px-5 pt-4 flex items-center justify-between">
                    <div>
                      <img width="0" height="0" className="h-8 w-auto" src="img/rehustle-appearance.svg" alt="" />
                    </div>
                    <div className="-mr-2">
                      <button
                        type="button"
                        onClick={(e) => handleHideMobileMenu(e)}
                        className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-inset focus:ring-brand-default">
                        <span className="sr-only">Close main menu</span>
                        <svg
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="px-2 pt-2 pb-3 space-y-1">
                    <a
                      href="#"
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 hidden">
                      How it works
                    </a>

                    <a
                      href="#"
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 hidden">
                      Services
                    </a>

                    <a
                      href="#"
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 hidden">
                      Example
                    </a>
                  </div>
                  <a
                    href="#"
                    onClick={(e) => login(e)}
                    className="block m-3 px-5 py-3 text-center font-medium text-brand-default bg-gray-50 hover:bg-gray-100">
                    Login
                  </a>

                  <a
                    href="#"
                    onClick={(e) => gettingStarted(e)}
                    className="block m-3 px-5 py-3 text-center font-medium text-brand-default bg-gray-50 hover:bg-gray-100">
                    Getting Started
                  </a>
                </div>
              </div>

              <div className=" lg:inset-y-0 lg:right-0 lg:w-1/2 sm:hidden">
                <img
                  width="0"
                  height="0"
                  className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
                  src="img/rehustle-home-banner.jpeg"
                  alt="banner"
                />
              </div>

              <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                <div className="sm:text-center lg:text-left">
                  <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                    <div className="block mb-2">Are you a next-gen </div>
                    <div className="block text-brand-default xl:inline">Creator?</div>
                  </h1>
                  <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                    Share your Content, Social links, Services and get paid easily via Paytm - with just one Profile
                    link
                  </p>
                  <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                    <div className="rounded-md shadow">
                      <a
                        href="#"
                        onClick={(e) => gettingStarted(e)}
                        className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-brand-default hover:brand-hover md:py-4 md:text-lg md:px-10">
                        Get started for free
                      </a>
                    </div>
                    <div className="mt-3 sm:mt-0 sm:ml-3 hidden">
                      <a
                        href="#"
                        className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10">
                        Live demo
                      </a>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
          <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 hidden sm:block">
            <img
              height="0"
              width="0"
              className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
              src="img/rehustle-home-banner.jpeg"
              alt="banner"
            />
          </div>
        </div>
        <Showcase />
        <ServicesInfo />
        <Pricing />
        <Callaction />
        <Faqs />
        <Cta2 />
        <Footer />
      </>
    </article>
  );
};

const LandingPageComp = LandingPage;
export default LandingPageComp;
