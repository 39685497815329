export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export type REGISTER_REQUEST = typeof REGISTER_REQUEST;

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export type REGISTER_SUCCESS = typeof REGISTER_SUCCESS;

export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export type REGISTER_FAILURE = typeof REGISTER_FAILURE;

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export type LOGIN_REQUEST = typeof LOGIN_REQUEST;

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export type LOGIN_SUCCESS = typeof LOGIN_SUCCESS;

export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export type LOGIN_FAILURE = typeof LOGIN_FAILURE;

export const LOGOUT = 'LOGOUT';
export type LOGOUT = typeof LOGOUT;

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export type GET_USER_REQUEST = typeof GET_USER_REQUEST;

export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export type GET_USER_SUCCESS = typeof GET_USER_SUCCESS;

export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export type GET_USER_FAILURE = typeof GET_USER_FAILURE;

export const DELETE_REQUEST = 'DELETE_REQUEST';
export type DELETE_REQUEST = typeof DELETE_REQUEST;

export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export type DELETE_SUCCESS = typeof DELETE_SUCCESS;

export const DELETE_FAILURE = 'DELETE_FAILURE';
export type DELETE_FAILURE = typeof DELETE_FAILURE;

export const POST_PROFILE_REQUEST = 'POST_PROFILE_REQUEST';
export type POST_PROFILE_REQUEST = typeof POST_PROFILE_REQUEST;

export const POST_PROFILE_SUCCESS = 'POST_PROFILE_SUCCESS';
export type POST_PROFILE_SUCCESS = typeof POST_PROFILE_SUCCESS;

export const POST_PROFILE_FAILURE = 'POST_PROFILE_FAILURE';
export type POST_PROFILE_FAILURE = typeof POST_PROFILE_FAILURE;

export const POST_LOGIN_PROFILE_REQUEST = 'POST_LOGIN_PROFILE_REQUEST';
export type POST_LOGIN_PROFILE_REQUEST = typeof POST_LOGIN_PROFILE_REQUEST;

export const POST_LOGIN_PROFILE_SUCCESS = 'POST_LOGIN_PROFILE_SUCCESS';
export type POST_LOGIN_PROFILE_SUCCESS = typeof POST_LOGIN_PROFILE_SUCCESS;

export const POST_LOGIN_PROFILE_FAILURE = 'POST_LOGIN_PROFILE_FAILURE';
export type POST_LOGIN_PROFILE_FAILURE = typeof POST_LOGIN_PROFILE_FAILURE;

export const REFRESH = 'REFRESH';
export type REFRESH = typeof REFRESH;

export const POST_UPDATE_NEW_PASSWORD_REQUEST = 'POST_UPDATE_NEW_PASSWORD_REQUEST';
export type POST_UPDATE_NEW_PASSWORD_REQUEST = typeof POST_UPDATE_NEW_PASSWORD_REQUEST;

export const POST_UPDATE_NEW_PASSWORD_SUCCESS = 'POST_UPDATE_NEW_PASSWORD_SUCCESS';
export type POST_UPDATE_NEW_PASSWORD_SUCCESS = typeof POST_UPDATE_NEW_PASSWORD_SUCCESS;

export const POST_UPDATE_NEW_PASSWORD_FAILURE = 'POST_UPDATE_NEW_PASSWORD_FAILURE';
export type POST_UPDATE_NEW_PASSWORD_FAILURE = typeof POST_UPDATE_NEW_PASSWORD_FAILURE;

export const GET_VERIFY_PASSWORD_REQUEST = 'GET_VERIFY_PASSWORD_REQUEST';
export type GET_VERIFY_PASSWORD_REQUEST = typeof GET_VERIFY_PASSWORD_REQUEST;

export const GET_VERIFY_PASSOWRD_SUCCESS = 'GET_VERIFY_PASSOWRD_SUCCESS';
export type GET_VERIFY_PASSOWRD_SUCCESS = typeof GET_VERIFY_PASSOWRD_SUCCESS;

export const GET_VERIFY_PASSWORD_FAILURE = 'GET_VERIFY_PASSWORD_FAILURE';
export type GET_VERIFY_PASSWORD_FAILURE = typeof GET_VERIFY_PASSWORD_FAILURE;

export const POST_RESET_PASSWORD_REQUEST = 'POST_RESET_PASSWORD_REQUEST';
export type POST_RESET_PASSWORD_REQUEST = typeof POST_RESET_PASSWORD_REQUEST;

export const POST_RESET_PASSWORD_SUCCESS = 'POST_RESET_PASSWORD_SUCCESS';
export type POST_RESET_PASSWORD_SUCCESS = typeof POST_RESET_PASSWORD_SUCCESS;

export const POST_RESET_PASSWORD_FAILURE = 'POST_RESET_PASSWORD_FAILURE';
export type POST_RESET_PASSWORD_FAILURE = typeof POST_RESET_PASSWORD_FAILURE;

export const POST_GOOGLE_SIGNIN_REQUEST = 'POST_GOOGLE_SIGNIN_REQUEST';
export type POST_GOOGLE_SIGNIN_REQUEST = typeof POST_GOOGLE_SIGNIN_REQUEST;

export const POST_GOOGLE_SIGNIN_SUCCESS = 'POST_GOOGLE_SIGNIN_SUCCESS';
export type POST_GOOGLE_SIGNIN_SUCCESS = typeof POST_GOOGLE_SIGNIN_SUCCESS;

export const POST_GOOGLE_SIGNIN_FAILURE = 'POST_GOOGLE_SIGNIN_FAILURE';
export type POST_GOOGLE_SIGNIN_FAILURE = typeof POST_GOOGLE_SIGNIN_FAILURE;

export const GET_TWITTER_USER_REQUEST = 'GET_TWITTER_USER_REQUEST';
export type GET_TWITTER_USER_REQUEST = typeof GET_TWITTER_USER_REQUEST;

export const GET_TWITTER_USER_SUCCESS = 'GET_TWITTER_USER_SUCCESS';
export type GET_TWITTER_USER_SUCCESS = typeof GET_TWITTER_USER_SUCCESS;

export const GET_TWITTER_USER_FAILURE = 'GET_TWITTER_USER_FAILURE';
export type GET_TWITTER_USER_FAILURE = typeof GET_TWITTER_USER_FAILURE;
