import React from 'react';
import { ISocialLinks } from '../helpers/initialState';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const linkify = function (text: any) {
  // http://, https://, ftp://
  const urlPattern = /\b(?:https?|ftp):\/\/[a-z0-9-+&@#/%?=~_|!:,.;]*[a-z0-9-+&@#/%=~_|]/gim;

  // www. sans http:// or https://
  const pseudoUrlPattern = /(^|[^/])(www\.[\S]+(\b|$))/gim;

  // Email addresses
  const emailAddressPattern = /[\w.]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+/gim;

  return text
    .replace(urlPattern, '$&')
    .replace(pseudoUrlPattern, 'http://$2')
    .replace(emailAddressPattern, 'mailto:$&');
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getLink = (props: ISocialLinks) => {
  return props.title === 'email' ? `mailto:${props.link}` : props.link;
};

const SocialLinkSection = (props: ISocialLinks): JSX.Element => {
  const { title, link } = props;
  return (
    <a
      href={linkify(link)}
      target="_blank"
      rel="noreferrer"
      className="transition duration-200 ease-in-out transform hover:scale-105 px-1 pb-3">
      <img
        alt={title}
        src={`/images/${title}.svg`}
        height="24"
        width="24"
        className="mx-auto object-cover rounded-full h-6 w-6"
      />
    </a>
  );
};

export default SocialLinkSection;
