/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-file no-use-before-define
import * as React from 'react';

function SvgMobile(props) {
  return (
    <svg width={54} height={54} viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.036 4.5C9.282 4.5 7.5 6.28 7.5 7.975v37.8c0 1.694 1.782 3.475 4.536 3.475h28.928c2.754 0 4.536-1.78 4.536-3.475v-37.8c0-1.694-1.782-3.475-4.536-3.475H12.036zM5 7.975C5 4.45 8.399 2 12.036 2h28.928C44.601 2 48 4.45 48 7.975v37.8c0 3.525-3.399 5.975-7.036 5.975H12.036C8.399 51.75 5 49.3 5 45.775v-37.8z"
        fill="inherit"
      />
      <path d="M28.5 42.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" fill="inherit" />
    </svg>
  );
}

export default SvgMobile;
