import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/** Component for implementing scroll to top as history route changes*/
const ScrollToTop = (): null => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return (): void => {
      unlisten();
    };
  }, [history]);

  return null;
};

export default ScrollToTop;
