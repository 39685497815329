import * as loadingConstants from '../Constants';
import { ILoadingClear, ILoadingSuccess } from './action.types';

export const loaderActions = {
  addLoader,
  removeLoader,
};

function addLoader(): ILoadingSuccess {
  return { type: loadingConstants.LOADERSUCCESS, loading: true };
}

function removeLoader(): ILoadingClear {
  return { type: loadingConstants.LOADERCLEAR, loading: false };
}
