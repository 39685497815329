/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import * as userConstants from '../Constants';
import { authService } from '../Services';
import { alertActions } from '.';
import { history } from '../helpers';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  RegisterUserInput,
  LoginUserInput,
  ILoginRequest,
  ILoginSuccess,
  ILoginFail,
  IRegisterRequest,
  IRegisterSuccess,
  IRegisterFailure,
  ILogout,
  IPostLoginProfileRequest,
  IPostLoginProfileSuccess,
  IPostLoginProfileFailure,
  IRefresh,
  IPostResetPasswordRequest,
  IPostResetPasswordSuccess,
  IPostResetPasswordFailure,
  IGetVerifyPasswordTokenSuccess,
  IGetVerifyPasswordTokenFailure,
  IGetVerifyPasswordTokenRequest,
  IPostUpdateNewPasswordFailure,
  IPostUpdateNewPasswordRequest,
  IPostUpdateNewPasswordSuccess,
  IPostGoogleSignInRequest,
  IPostGoogleSignInSuccess,
  IPostGoogleSignInFailure,
} from './action.types';
import { APP_ROUTES } from '../Utils/routeConstant';
import { IUserProfile, IApiError } from '../helpers/initialState';
import toast from 'react-hot-toast';

export const loginRequest = (user: LoginUserInput): ILoginRequest => {
  return { type: userConstants.LOGIN_REQUEST, user };
};

export const loginSuccess = (user: LoginUserInput): ILoginSuccess => {
  return { type: userConstants.LOGIN_SUCCESS, user };
};

export const loginFailure = (error: IApiError): ILoginFail => {
  return { type: userConstants.LOGIN_FAILURE, error };
};

export const registerRequest = (user: RegisterUserInput): IRegisterRequest => {
  return { type: userConstants.REGISTER_REQUEST, user };
};

export const registerSuccess = (user: RegisterUserInput): IRegisterSuccess => {
  return { type: userConstants.REGISTER_SUCCESS, user };
};

export const registerFailure = (error: IApiError): IRegisterFailure => {
  return { type: userConstants.REGISTER_FAILURE, error };
};

export const postLoginProfileUpadtesRequest = (): IPostLoginProfileRequest => {
  return { type: userConstants.POST_LOGIN_PROFILE_REQUEST };
};

export const postLoginProfileUpadtesSuccess = (user: IUserProfile): IPostLoginProfileSuccess => {
  return { type: userConstants.POST_LOGIN_PROFILE_SUCCESS, user };
};

export const postLoginProfileUpadtesFailure = (error: IApiError): IPostLoginProfileFailure => {
  return { type: userConstants.POST_LOGIN_PROFILE_FAILURE, error };
};

export const postResetPasswordRequest = (): IPostResetPasswordRequest => {
  return { type: userConstants.POST_RESET_PASSWORD_REQUEST };
};

export const postResetPasswordSuccess = (email: string): IPostResetPasswordSuccess => {
  return { type: userConstants.POST_RESET_PASSWORD_SUCCESS, email };
};

export const postResetPasswordFailure = (error: IApiError): IPostResetPasswordFailure => {
  return { type: userConstants.POST_RESET_PASSWORD_FAILURE, error };
};

export const getVerifyResetPasswordTokenRequest = (): IGetVerifyPasswordTokenRequest => {
  return { type: userConstants.GET_VERIFY_PASSWORD_REQUEST };
};

export const getVerifyResetPasswordTokenSuccess = (token: string): IGetVerifyPasswordTokenSuccess => {
  return { type: userConstants.GET_VERIFY_PASSOWRD_SUCCESS, token };
};

export const getVerifyResetPasswordTokenFailure = (error: IApiError): IGetVerifyPasswordTokenFailure => {
  return { type: userConstants.GET_VERIFY_PASSWORD_FAILURE, error };
};

export const postUpdateNewPasswordRequest = (): IPostUpdateNewPasswordRequest => {
  return { type: userConstants.POST_UPDATE_NEW_PASSWORD_REQUEST };
};

export const postUpdateNewPasswordSuccess = (token: string): IPostUpdateNewPasswordSuccess => {
  return { type: userConstants.POST_UPDATE_NEW_PASSWORD_SUCCESS, token };
};

export const postUpdateNewPasswordFailure = (error: IApiError): IPostUpdateNewPasswordFailure => {
  return { type: userConstants.POST_UPDATE_NEW_PASSWORD_FAILURE, error };
};

export const postGoogleSignInRequest = (): IPostGoogleSignInRequest => {
  return { type: userConstants.POST_GOOGLE_SIGNIN_REQUEST };
};

export const postGoogleSignInSuccess = (user: RegisterUserInput): IPostGoogleSignInSuccess => {
  return { type: userConstants.POST_GOOGLE_SIGNIN_SUCCESS, user };
};

export const postGoogleSignInFailure = (error: IApiError): IPostGoogleSignInFailure => {
  return { type: userConstants.POST_GOOGLE_SIGNIN_FAILURE, error };
};

export const refreshUser = (): IRefresh => {
  return { type: userConstants.REFRESH };
};

export const logout = (): ILogout => {
  authService.logout();
  return { type: userConstants.LOGOUT };
};

export const login = (email: string, password: string, from: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    const loadingToastId = toast.loading('Logging in');
    await dispatch(loginRequest({ email, password }));
    await dispatch(postLoginProfileUpadtesRequest());

    authService
      .login(email, password)
      .then(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (user: any) => {
          dispatch(loginSuccess(user));
          dispatch(postLoginProfileUpadtesSuccess(user));
          localStorage.setItem('token', JSON.stringify(user.token));
          toast.dismiss(loadingToastId);
          toast.success('Logged In Successfully');
          history.push(from);
        },
      )
      .catch((user) => {
        dispatch(loginFailure(user?.data));
        dispatch(postLoginProfileUpadtesFailure(user?.data));
        toast.dismiss(loadingToastId);
        dispatch(alertActions.error(user?.data));
      });
  };
};

export const register = (user: RegisterUserInput): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    const loadingToastId = toast.loading('Registering');
    await dispatch(registerRequest(user));
    await dispatch(postLoginProfileUpadtesRequest());

    authService
      .register(user)
      .then((user: any) => {
        dispatch(registerSuccess(user));
        dispatch(postLoginProfileUpadtesSuccess(user));
        localStorage.setItem('token', JSON.stringify(user.token));
        dispatch(alertActions.success('Registration successful'));
        toast.dismiss(loadingToastId);
        toast.success('Registration successful');
        history.push(APP_ROUTES.IMPORT);
      })
      .catch((user) => {
        dispatch(registerFailure(user.data));
        dispatch(postLoginProfileUpadtesFailure(user.data));
        toast.dismiss(loadingToastId);
        dispatch(alertActions.error(user.data));
      });
  };
};

export const resetPassword = (email: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    const loadingToastId = toast.loading('Sending Reset password Link');
    await dispatch(postResetPasswordRequest());

    authService
      .resetPassword(email)
      .then((user: any) => {
        dispatch(postResetPasswordSuccess(user));
        toast.dismiss(loadingToastId);
        toast.success('Reset password link Sent Successfully');
        dispatch(alertActions.success('Successful'));
      })
      .catch((user) => {
        dispatch(postResetPasswordFailure(user.data));
        toast.dismiss(loadingToastId);
        toast.error('Some error occurred');
        dispatch(alertActions.error(user.data));
      });
  };
};

export const updatePassword = (password: string, token: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(postUpdateNewPasswordRequest());

    authService
      .updateNewPassword(password, token)
      .then((user: any) => {
        dispatch(postUpdateNewPasswordSuccess(user));
        dispatch(alertActions.success('Password Update successful'));
        toast.success('Password updated Successfully');
        history.push(APP_ROUTES.LOGIN);
      })
      .catch((user) => {
        dispatch(postUpdateNewPasswordFailure(user.data));
        dispatch(alertActions.error(user.data));
      });
  };
};

export const verifyResetPasswordToken = (token: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(getVerifyResetPasswordTokenRequest());

    authService
      .verifyResetPasswordToken(token)
      .then((user: any) => {
        dispatch(getVerifyResetPasswordTokenSuccess(user));
        dispatch(alertActions.success('Registration successful'));
      })
      .catch((user) => {
        dispatch(getVerifyResetPasswordTokenFailure(user.data));
        dispatch(alertActions.error(user.data));
      });
  };
};

export const googleSignUp = (accessToken: string, userName: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    const loadingToastId = toast.loading('Registering');
    await dispatch(postGoogleSignInRequest);
    await dispatch(postLoginProfileUpadtesRequest());

    authService
      .googleSignUp(accessToken, userName)
      .then((user: any) => {
        dispatch(postGoogleSignInSuccess(user));
        dispatch(postLoginProfileUpadtesSuccess(user));
        dispatch(alertActions.success('Registration successful'));
        localStorage.setItem('token', JSON.stringify(user.token));
        toast.dismiss(loadingToastId);
        toast.success('Registration successful');
        history.push(APP_ROUTES.IMPORT);
      })
      .catch((user) => {
        dispatch(postGoogleSignInSuccess(user.data));
        dispatch(postLoginProfileUpadtesFailure(user.data));
        toast.dismiss(loadingToastId);
        dispatch(alertActions.error(user.data));
      });
  };
};

export const googleSignIn = (accessToken: string, from: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    const loadingToastId = toast.loading('Logging in');
    //await dispatch(loginRequest({ email, password }));
    await dispatch(postLoginProfileUpadtesRequest());

    authService
      .googleSignIn(accessToken)
      .then(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (user: any) => {
          dispatch(loginSuccess(user));
          dispatch(postLoginProfileUpadtesSuccess(user));
          localStorage.setItem('token', JSON.stringify(user.token));
          toast.dismiss(loadingToastId);
          toast.success('Logged In Successfully');
          history.push(from);
        },
      )
      .catch((user) => {
        dispatch(loginFailure(user.data));
        dispatch(postLoginProfileUpadtesFailure(user.data));
        toast.dismiss(loadingToastId);
        dispatch(alertActions.error(user.data));
      });
  };
};

export const userActions = {
  login,
  logout,
  register,
  refreshUser,
  resetPassword,
  updatePassword,
  verifyResetPasswordToken,
  googleSignUp,
  googleSignIn,
};
