/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import { ILink, IProduct } from '../helpers/initialState';
import { ISocialLinks } from '../helpers/initialState';
import PaymentInfoPopup from '../Payments/PaymentInfoPopup';
import ServiceCard from './ServiceCard';
import LinkSection from './LinkSection';
//import { SocialLinkSection } from './SocialLinksSection';
import TemplateHeader from './TemplateHeader';
import FloatingBottom from '../Components/FloatingBottom';

interface ITemplateProps {
  name: string;
  description: string;
  socialLinks?: ISocialLinks[];
  profileUrls?: ILink[];
  products?: IProduct[];
  link: string;
  openForm?: boolean;
  appearance?: string;
  username?: string;
  handleFormPopupClose?: (event: React.SyntheticEvent) => void;
  selectedProduct?: string;
  handleCardClick?: (event: React.SyntheticEvent, _id: string | undefined) => void;
  showClickButton?: boolean;
}
const Template1: React.FC<ITemplateProps> = (props: ITemplateProps) => {
  const {
    name,
    description,
    products,
    socialLinks,
    profileUrls,
    link,
    openForm,
    appearance,
    handleFormPopupClose,
    handleCardClick,
    selectedProduct,
  } = props;
  const appliedStyles = appearance && JSON.parse(appearance);
  return (
    <React.Fragment>
      <main className="profile-page w-full h-screen md:px-6 xl:px-0 bg-gray-10">
        <section className="relative md:pt-2 md:pt-4 w-full md:max-w-sm md:max-w-2xl mx-auto">
          <TemplateHeader name={name} link={link} socialLinks={socialLinks} appearance={appearance} />
        </section>
        {description?.length ? (
          <section className="relative md:pt-2 max-w-sm md:max-w-2xl mx-auto px-6 md:px-0">
            <div
              className="font-bold py-6 text-2xl text-gray-2"
              style={appliedStyles?.color ? { color: appliedStyles?.color } : {}}>
              About
            </div>
            <div
              className={`overflow-clip bg-white max-w-2xl mx-auto shadow break-words ${
                appliedStyles?.buttonRounded || 'rounded-xl'
              }`}>
              <p className="p-4 text-sm text-gray-2">{description}</p>
            </div>
          </section>
        ) : (
          <></>
        )}
        {profileUrls?.length ? (
          <section className="relative md:pt-2 md:pt-4 max-w-sm md:max-w-2xl mx-auto px-6 md:px-0">
            <div
              className="font-bold py-6 text-2xl text-gray-2"
              style={appliedStyles?.color ? { color: appliedStyles?.color } : {}}>
              Links
            </div>
            {profileUrls &&
              profileUrls.map((link, index) => (
                <LinkSection key={`link-${index}`} {...link} appliedStyles={appliedStyles} />
              ))}
          </section>
        ) : (
          <></>
        )}
        {products?.length ? (
          <section className="relative md:pt-2 max-w-sm md:max-w-2xl mx-auto px-6 md:px-0">
            <div
              className="font-bold py-6 text-2xl text-gray-2"
              style={appliedStyles?.color ? { color: appliedStyles?.color } : {}}>
              Products & Services
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-4">
              {products &&
                products.map((product, index) => (
                  <ServiceCard
                    key={`product-${index}`}
                    {...product}
                    handleCardClick={handleCardClick}
                    showClickButton={true}
                    appearance={appearance}
                  />
                ))}
            </div>
          </section>
        ) : (
          <></>
        )}
        {props.handleCardClick ? <FloatingBottom /> : <div className="p-8" />}
      </main>
      {openForm ? (
        <PaymentInfoPopup
          handleFormPopupClose={handleFormPopupClose}
          selectedProduct={selectedProduct}
          username={props.username}
        />
      ) : null}
    </React.Fragment>
  );
};

export default Template1;
