/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-file no-use-before-define
import * as React from 'react';

function SvgVideo(props) {
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5.417A2.417 2.417 0 012.417 3h9.166A2.417 2.417 0 0114 5.417V7.64a.25.25 0 00.395.204l4.252-3.038a.75.75 0 011.186.61v8.334a.75.75 0 01-1.186.61l-4.252-3.037a.25.25 0 00-.395.204v2.223a2.417 2.417 0 01-2.417 2.417H2.417A2.417 2.417 0 010 13.75V5.417zM2.417 4.5a.917.917 0 00-.917.917v8.333c0 .506.41.917.917.917h9.166c.507 0 .917-.41.917-.917V5.417a.917.917 0 00-.917-.917H2.417zm15.916 2.86a.25.25 0 00-.395-.204L14.825 9.38a.25.25 0 000 .407l3.113 2.223a.25.25 0 00.395-.203V7.36z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgVideo;
