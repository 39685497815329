/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { apiUrls } from '../Utils/apiUrls';
import axiosInstance from '../Utils/interceptor';

const getInsights = async (): Promise<void> => {
  const { data } = await axiosInstance.get(`${apiUrls.GET_INSIGHTS}`);
  return data.result;
};

export const insightsService = {
  getInsights,
};
