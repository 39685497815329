import React from 'react';
import { ISocialLinks } from '../helpers/initialState';
import SocialLinkSection from './SocialLinksSection';
//import { defaultSocialLinks } from '../SocialLinks';
import { APP_CONSTANTS } from '../Utils/routeConstant';
interface ITemplateHeaderProps {
  name: string;
  link: string;
  socialLinks?: ISocialLinks[];
  appearance?: string;
}
const TemplateHeader: React.FC<ITemplateHeaderProps> = (props: ITemplateHeaderProps) => {
  const { name, link, appearance, socialLinks } = props;
  const appliedStyles = appearance && JSON.parse(appearance);
  return (
    <div
      className={`overflow-clip bg-white w-full mx-auto shadow md:${
        appliedStyles?.buttonRounded ? appliedStyles?.buttonRounded : 'rounded-xl'
      }`}>
      <div
        className={`h-56 md:h-64 w-full bg-gray-6 bg-gradient-to-r from-brand-default bg-cover bg-center overflow-clip bg-no-repeat md:${
          appliedStyles?.buttonRounded ? appliedStyles?.buttonRounded : 'rounded-xl'
        } md:rounded-b-none`}
        style={{
          backgroundImage: `url(${appliedStyles?.bannerImage || ''})`,
        }}
      />
      <div className="md:pb-8 flex flex-col md:justify-between md:flex-row md:items-start w-full md:px-6">
        {/** test */}
        <div className="flex justify-center flex-col md:flex-row">
          <div className="flex justify-center">
            <div
              style={{
                backgroundImage: appliedStyles?.profileImage
                  ? `url(${appliedStyles?.profileImage})`
                  : `url('/img/rehustle-placeholder-profile.png')`,
                width: '120px',
                height: '120px',
              }}
              className="shadow-xl rounded-xl align-middle border-4 border-white object-fit bg-cover -mt-16 md:-mt-12"
            />
          </div>
          <div className="pt-5 flex px-4 flex-col">
            <div
              className="text-lg lg:text-xl text-gary-2 font-bold text-center md:text-left leading-none truncate w-full md:w-40 lg:w-56"
              title={name}
              style={appliedStyles?.color ? { color: appliedStyles?.color } : {}}>
              {name}
            </div>
            <div className="text-xs font-medium text-center sm:text-left">
              {APP_CONSTANTS.PROFILE_HOST_DOMAIN}
              {link}
            </div>
          </div>
        </div>
        <div className="mx-6 md:mx-0 py-5 md:w-2/4 flex flex-wrap justify-center md:justify-end">
          {socialLinks?.length ? (
            socialLinks && socialLinks.map((link, index) => <SocialLinkSection key={`sociallink-${index}`} {...link} />)
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default TemplateHeader;
