/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */

import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { Close } from '../../assets';
import { userActions } from '../Actions';
import { APP_ROUTES } from '../Utils/routeConstant';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// interface IResetPasswordProps {
//   email: string;
// }
const ResetPasswordContainer: React.FC = () => {
  const query = useQuery();
  const token = query.get('token');
  const dispatch = useDispatch();
  const history = useHistory();
  const handleClose = () => {
    history.push(APP_ROUTES.MAIN);
  };
  const [inputs, setInputs] = useState({
    newpassword: '',
    matchNewPassword: '',
  });
  const [validation, setValidation] = useState({
    passwordEquality: true,
  });
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setSubmitted(true);
    if (inputs.newpassword === inputs.matchNewPassword && token) {
      dispatch(userActions.updatePassword(inputs.newpassword, token));
    } else {
      setValidation({ ...validation, passwordEquality: false });
    }
  };
  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }
  useEffect(() => {
    if (token) {
      dispatch(userActions.verifyResetPasswordToken(token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="justify-center w-full items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 mx-auto max-w-xs w-96">
          {/*content*/}
          <div className="border-0 rounded-2xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="absolute rounded top-0 z-10 w-full px-3 py-3 flex justify-between items-center">
              <div className="font-bold text-lg ml-3">Reset Passord</div>
              <div className="flex items-center mr-3">
                <button
                  onClick={handleClose}
                  className="w-full text-gray-3 rounded-full hover:bg-gray-100 hover:text-black  p-1 focus:outline-none">
                  <Close />
                </button>
              </div>
            </div>
            {/*body*/}
            <div className="h-80  false mt-14">
              <div className="p-6">
                <div className="mb-5">
                  <label className="flex text-xs font-bold tracking-tight text-gray-1">
                    <div className="flex flex-1">New Password</div>
                  </label>
                  <div className="flex rounded mt-2">
                    <input
                      type="password"
                      name="newpassword"
                      placeholder="New Password"
                      required={true}
                      onChange={handleChange}
                      className={`flex-1 form-input block w-full min-w-0 rounded transition duration-150 ease-in-out sm:text-sm sm:leading-5 disabled:bg-gray-100 p-2 border focus:outline-none text-black disabled:text-gray-400${
                        submitted && validation.passwordEquality ? 'border-brand-error' : 'border-gray-6'
                      }`}
                    />
                  </div>
                </div>
                <div className="mb-5">
                  <label className="flex text-xs font-bold tracking-tight text-gray-1">
                    <div className="flex flex-1">Confirm new password</div>
                  </label>
                  <div className="relative flex flex-col mt-2">
                    <input
                      type="password"
                      name="matchNewPassword"
                      placeholder="Confirm Password"
                      required={true}
                      onChange={handleChange}
                      className={`flex-1 form-input block w-full min-w-0 rounded transition duration-150 ease-in-out sm:text-sm sm:leading-5 disabled:bg-gray-100 p-2 border focus:outline-none text-black disabled:text-gray-400${
                        submitted && validation.passwordEquality ? 'border-brand-error' : 'border-gray-6'
                      }`}
                    />
                  </div>
                </div>
                {submitted && validation.passwordEquality && (
                  <div className="p-0.5 text-xs text-brand-error">Password should be equal</div>
                )}
                <div className="flex w-full xs:mt-6">
                  <button
                    type="submit"
                    onClick={(e) => handleSubmit(e)}
                    className="py-2 px-4  bg-brand-default hover:bg-brand-default focus:ring-brand-default focus:ring-offset-brand-default text-white w-full duration-150 disabled:cursor-default rounded-lg transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-1 focus:ring-offset-2">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-40 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default ResetPasswordContainer;
