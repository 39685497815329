export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST';
export type GET_PRODUCT_REQUEST = typeof GET_PRODUCT_REQUEST;

export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export type GET_PRODUCT_SUCCESS = typeof GET_PRODUCT_SUCCESS;

export const GET_PRODUCT_FAILURE = 'GET_PRODUCT_FAILURE';
export type GET_PRODUCT_FAILURE = typeof GET_PRODUCT_FAILURE;

export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST';
export type DELETE_PRODUCT_REQUEST = typeof DELETE_PRODUCT_REQUEST;

export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export type DELETE_PRODUCT_SUCCESS = typeof DELETE_PRODUCT_SUCCESS;

export const DELETE_PRODUCT_FAILURE = 'DELETE_PRODUCT_FAILURE';
export type DELETE_PRODUCT_FAILURE = typeof DELETE_PRODUCT_FAILURE;

export const POST_UPDATE_PRODUCT_REQUEST = 'POST_UPDATE_PRODUCT_REQUEST';
export type POST_UPDATE_PRODUCT_REQUEST = typeof POST_UPDATE_PRODUCT_REQUEST;

export const POST_UPDATE_PRODUCT_SUCCESS = 'POST_UPDATE_PRODUCT_SUCCESS';
export type POST_UPDATE_PRODUCT_SUCCESS = typeof POST_UPDATE_PRODUCT_SUCCESS;

export const POST_UPDATE_PRODUCT_FAILURE = 'POST_UPDATE_PRODUCT_FAILURE';
export type POST_UPDATE_PRODUCT_FAILURE = typeof POST_UPDATE_PRODUCT_FAILURE;

export const SELECT_PRODUCT_SUCCESS = 'SELECT_PRODUCT_SUCCESS';
export type SELECT_PRODUCT_SUCCESS = typeof SELECT_PRODUCT_SUCCESS;

export const ADD_PRODUCT_FAILURE = 'ADD_PRODUCT_FAILURE';
export type ADD_PRODUCT_FAILURE = typeof ADD_PRODUCT_FAILURE;

export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export type ADD_PRODUCT_SUCCESS = typeof ADD_PRODUCT_SUCCESS;

export const ADD_PRODUCT_REQUEST = 'ADD_PRODUCT_REQUEST';
export type ADD_PRODUCT_REQUEST = typeof ADD_PRODUCT_REQUEST;
