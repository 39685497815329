/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { apiUrls } from '../Utils/apiUrls';
import axiosInstance from '../Utils/interceptor';
/**
 *
 * @returns profile of user and set it in store
 *
 */
const getCurrentUser = async (): Promise<void> => {
  const userSessionActive = localStorage.getItem('token');
  const { data } = await axiosInstance.get(`${apiUrls.GET_CURRENT_USER}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: userSessionActive ? `Bearer ${JSON.parse(userSessionActive)}` : ``,
    },
  });
  return data.result;
};
/**
 *
 * @param userProfile
 * @returns success and error message
 */
const postProfileUpdate = async (userProfile: any): Promise<void> => {
  const { data } = await axiosInstance.put(`${apiUrls.PUT_UPDATE_USER_PROFILE}`, { ...userProfile });
  return data.result;
};

const getTwitterUser = async (username: string): Promise<void> => {
  const { data } = await axiosInstance.get(`${apiUrls.GET_TWITTER_USER}/${username}`);
  return data.result;
};

export const userProfileService = {
  getCurrentUser,
  postProfileUpdate,
  getTwitterUser,
};
