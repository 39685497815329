/* eslint-disable @typescript-eslint/no-explicit-any */
import { apiUrls } from '../Utils/apiUrls';
import axiosInstance from '../Utils/interceptor';

async function getPublicProfile(username: string): Promise<void> {
  const { data } = await axiosInstance.get(`${apiUrls.GET_PUBLIC_PROFILE}/${username}`);
  return data.result;
}

export const publicProfileService = {
  getPublicProfile,
};
