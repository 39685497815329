import React from 'react';

const Loader: React.FC = () => {
  return (
    <>
      <div className="w-full h-full fixed inset-0 z-50 overflow-hidden flex flex-col items-center justify-center">
        <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-6 h-10 w-10 mb-2 z-50"></div>
        <h2 className="text-center text-brand-default text-base">Loading...</h2>
      </div>
      <div className="opacity-60 fixed inset-0 z-40 bg-white"></div>
    </>
  );
};

export default Loader;
