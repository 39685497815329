import React from 'react';
import moment from 'moment';
import { ServiceTypeCard } from '../interface/template';
import { Video, Rupee, Calendar, Time } from '../../../assets';

const getPriceButtonText = (props: ServiceTypeCard) => {
  return props.paymentMode === 'free' ? 'Get for Free' : `Purchase for ${props.price.currency} ${props.price.amount}`;
};

const EventCard: React.FC<ServiceTypeCard> = (props): JSX.Element => {
  const initTime = props.service?.date ? moment(props.service?.date).format('HH:mm') : '00:00';
  const date = moment(props.service?.date).format();
  const endTime = props.service?.duration ? moment(date).add(parseInt(props.service?.duration), 'minutes') : '00:00';
  const end = moment(endTime).format('HH:mm');
  const formatdate = moment(props.service?.date).format('MMM Do, YYYY');
  const appliedStyles = props.appearance && JSON.parse(props.appearance);
  return (
    <div className="flex flex-col pt-4 text-sm font-medium">
      <div className="grid grid-cols-2 gap-4 text-sm font-medium">
        <div className="flex flex-1 items-center space-x-2">
          <span>
            <Video />
          </span>
          <span>Event</span>
        </div>
        <div className="flex flex-1 items-center space-x-2">
          <span>
            <Rupee />
          </span>
          <span>{props.price.amount}</span>
        </div>
        <div className="flex flex-1 items-center space-x-2">
          <span>
            <Calendar />
          </span>
          <span>{formatdate}</span>
        </div>
        <div className="flex flex-1 items-center space-x-2">
          <span>
            <Time />
          </span>
          <span>
            {initTime} to {end}
          </span>
        </div>
      </div>
      <div className="mt-2">
        {props.showClickButton && (
          <button
            onClick={(e) => props.handleCardClick && props.handleCardClick(e, props._id)}
            className={`bg-brand-default px-4 py-2 my-4 text-white font-semibold  focus:outline-none transition duration-200 ease-in-out transform hover:bg-brand-hover w-full ${
              appliedStyles?.buttonRounded || 'rounded'
            }`}
            style={appliedStyles?.color ? { background: appliedStyles?.color } : {}}>
            {moment(new Date()).isBefore(props.service?.date, 'minute')
              ? getPriceButtonText(props)
              : 'This event has ended'}
          </button>
        )}
      </div>
    </div>
  );
};

export default EventCard;
