import React from 'react';

const ContactUsPage: React.FC = () => {
  return (
    <main className="bg-white">
      <div className="px-4 pb-16 pt-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
          <div className="flex flex-col mb-10 sm:text-center sm:mb-0">
            <a href="/" className="mb-6 mx-auto">
              <div className="flex items-center justify-center w-12 h-12 rounded-full bg-indigo-50 text-brand-default">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                  />
                </svg>
              </div>
            </a>
            <div className="max-w-xl mb-10 mx-auto sm:text-center lg:max-w-2xl md:mb-8">
              <h2 className="max-w-lg text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                <span className="relative inline-block">
                  <svg
                    viewBox="0 0 52 24"
                    fill="currentColor"
                    className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-gray-7 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block">
                    <defs>
                      <pattern id="e77df901-b9d7-4b9b-822e-16b2d410795b" x="0" y="0" width=".135" height=".30">
                        <circle cx="1" cy="1" r=".7" />
                      </pattern>
                    </defs>
                    <rect fill="url(#e77df901-b9d7-4b9b-822e-16b2d410795b)" width="52" height="24" />
                  </svg>
                  <span className="relative">Contact</span>
                </span>{' '}
                us
              </h2>
            </div>
          </div>
        </div>
        <div className="relative">
          <div className="relative bg-gray-10">
            <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
              <div className="flex flex-col items-center justify-between xl:flex-row">
                <div className="w-full max-w-xl mb-12 xl:mb-0 xl:pr-16 xl:w-7/12">
                  <h2 className="max-w-lg mb-6 text-4xl font-bold tracking-tight text-gray-1 sm:text-4xl sm:leading-none">
                    Drop us a message
                  </h2>
                  <p className="max-w-xl mb-4 text-base text-gray-400 md:text-lg">
                    Ask anything or give feedback. We will get back to you at a lightning fast speed.
                  </p>
                  <a
                    href="mailto:hello@rehustle.co"
                    aria-label=""
                    className="inline-flex items-center font-semibold tracking-wider transition-colors duration-200 text-gray-2 hover:text-gray-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>
                    hello@rehustle.co
                  </a>
                  <p className="text-gray-2 hover:text-gray-1">
                    <a
                      href="mailto:hello@rehustle.co"
                      aria-label=""
                      className="inline-flex items-center font-semibold tracking-wider transition-colors duration-200 text-gray-2 hover:text-gray-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 mr-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                      I-5, Kirti Nagar, Delhi-110015
                    </a>
                  </p>
                </div>
                <div className="w-full max-w-xl xl:px-8 xl:w-5/12">
                  <div className="bg-white rounded shadow-2xl p-7 sm:p-10">
                    <h3 className="mb-4 text-xl font-semibold text-gray-1 sm:text-center sm:mb-6 sm:text-2xl">
                      Fill up the form
                    </h3>
                    <form method="POST" action="https://rehustle.gpages.live/contact">
                      <div className="mb-1 sm:mb-2">
                        <label htmlFor="firstName" className="inline-block mb-1 font-medium">
                          Your Name
                        </label>
                        <input
                          placeholder="John"
                          required
                          type="text"
                          className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                          id="firstName"
                          name="contact_name"
                        />
                      </div>
                      <div className="mb-1 sm:mb-2">
                        <label htmlFor="email" className="inline-block mb-1 font-medium">
                          Your Email
                        </label>
                        <input
                          placeholder="john.doe@example.org"
                          required
                          type="text"
                          className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                          id="email"
                          name="contact_email"
                        />
                      </div>
                      <div className="mb-1 sm:mb-2">
                        <label htmlFor="message" className="inline-block mb-1 font-medium">
                          Your message
                        </label>
                        <textarea
                          id="message"
                          name="message"
                          required
                          placeholder="Enter your message"
                          className="w-full px-4 py-2 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                          rows={4}></textarea>
                      </div>
                      <div className="mt-4 mb-2 sm:mb-4">
                        <button
                          type="submit"
                          className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-brand-default hover:bg-brand-hover focus:shadow-outline focus:outline-none">
                          Submit
                        </button>
                      </div>
                      <p className="text-xs text-gray-600 sm:text-sm hidden">We respect your privacy</p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ContactUsPage;
