/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-file no-use-before-define
import * as React from 'react';

function SvgRupee(props) {
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.774 5.783v1.24h2.742c.607 0 1.13.362 1.367.881H6.774v1.241h4.109c-.237.52-.76.88-1.367.88H6.774v1.284l4.202 4.2.877-.877-3.366-3.365h1.03a2.746 2.746 0 002.67-2.122h1.039v-1.24h-1.039a2.73 2.73 0 00-.377-.881h1.415V5.783h-6.45zm4.255 3.263zm1.078-1.043z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 10c0 2.404.936 4.664 2.636 6.364A8.942 8.942 0 0010 19a8.942 8.942 0 006.364-2.636A8.942 8.942 0 0019 10a8.942 8.942 0 00-2.636-6.364A8.942 8.942 0 0010 1a8.942 8.942 0 00-6.364 2.636A8.942 8.942 0 001 10zm14.486 5.486A7.708 7.708 0 0110 17.76a7.708 7.708 0 01-5.487-2.273A7.708 7.708 0 012.241 10c0-2.073.807-4.02 2.272-5.487A7.708 7.708 0 0110 2.241c2.073 0 4.02.807 5.486 2.272A7.708 7.708 0 0117.76 10c0 2.073-.807 4.02-2.273 5.486zm.07-11.042z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgRupee;
