/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-file no-use-before-define
import * as React from 'react';

function SvgClock(props) {
  return (
    <svg width={54} height={54} viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 2.842C13.658 2.842 2.842 13.658 2.842 27S13.658 51.158 27 51.158 51.158 40.342 51.158 27 40.342 2.842 27 2.842zM0 27C0 12.088 12.088 0 27 0s27 12.088 27 27-12.088 27-27 27S0 41.912 0 27z"
        fill="inherit"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 10.232c.785 0 1.421.636 1.421 1.42v14.47l9.446 4.723a1.42 1.42 0 11-1.27 2.542L26.363 28.27A1.421 1.421 0 0125.58 27V11.653c0-.785.636-1.421 1.421-1.421z"
        fill="inherit"
      />
    </svg>
  );
}

export default SvgClock;
