import React from 'react';
import { ServiceTypeCard } from '../interface/template';
import { Chat1, Rupee } from '../../../assets';

const getPriceButtonText = (props: ServiceTypeCard) => {
  return props.paymentMode === 'free' ? 'Get for Free' : `Purchase for ${props.price.currency} ${props.price.amount}`;
};

const ChatCard: React.FC<ServiceTypeCard> = (props): JSX.Element => {
  const { price, _id } = props;
  const appliedStyles = props.appearance && JSON.parse(props.appearance);
  return (
    <div className="flex flex-col pt-4 text-sm font-medium">
      <div className="grid grid-cols-2 gap-4 text-sm font-medium">
        <div className="flex flex-1 items-center space-x-2">
          <span>
            <Chat1 />
          </span>
          <span>{props.service.serviceType}</span>
        </div>
        <div className="flex flex-1 items-center space-x-2">
          <span>
            <Rupee />
          </span>
          <span>{price.amount}</span>
        </div>
      </div>
      <div className="mt-2">
        {props.showClickButton && (
          <button
            onClick={(e) => props.handleCardClick && _id && props.handleCardClick(e, _id)}
            className={`bg-brand-default px-4 py-2 my-4 text-white font-semibold  focus:outline-none transition duration-200 ease-in-out transform hover:bg-brand-hover w-full rounded`}
            style={appliedStyles?.color ? { background: appliedStyles?.color } : {}}>
            {getPriceButtonText(props)}
          </button>
        )}
      </div>
    </div>
  );
};

export default ChatCard;
