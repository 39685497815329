import { combineReducers } from 'redux';
import { userProfile } from './userProfile.reducer';
import { user } from './auth.reducers';
import { alert } from './alert.reducer';
import { currentProduct } from './product.reducer';
import { products } from './allproducts.reducer';
import { publicProfile } from './publicProfile.reducer';
import { activateService } from './activateProduct.reducer';
import { loading } from './loader.reducer';
import { insights } from './insights.reducer';

const rootReducer = combineReducers({
  user,
  userProfile,
  alert,
  currentProduct,
  products,
  publicProfile,
  activateService,
  loading,
  insights,
});

export default rootReducer;
