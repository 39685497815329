/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-file no-use-before-define
import * as React from 'react';

function SvgChat1(props) {
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.006 4.423H5.16a.737.737 0 000 1.474h8.846a.737.737 0 000-1.474zM11.058 7.372H5.16a.737.737 0 000 1.474h5.898a.737.737 0 000-1.474z"
        fill="#000"
      />
      <path
        d="M16.923 0H3.077C1.38 0 0 1.323 0 2.949v14.743c0 .286.172.547.443.668a.79.79 0 00.818-.102l4.402-3.514h11.26c1.697 0 3.077-1.323 3.077-2.95V2.95C20 1.323 18.62 0 16.923 0zm1.539 11.795c0 .812-.69 1.474-1.539 1.474H5.385c-.18 0-.354.06-.493.171l-3.354 2.68V2.948c0-.813.69-1.475 1.539-1.475h13.846c.85 0 1.539.662 1.539 1.475v8.846z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgChat1;
