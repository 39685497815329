/* eslint-disable react/jsx-no-target-blank */
import React from 'react';

const FloatingBottom = (): JSX.Element => {
  return (
    <div className="gr-xeuibg-footer-container py-8 mt-4">
      <div className="gr-xdfgbg-footer">
        <div className="gr-dsf-badge-container">
          <a
            href={`https://rehustle.co/?ref=${window.location.pathname.replace('/', '')}`}
            target="_blank"
            className="gr-uijhh-button">
            <span className="text-brand-default">ReHustle</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default FloatingBottom;
