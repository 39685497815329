/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { IPublicProfile, InitialStore } from '../helpers/initialState';
import { Helmet } from 'react-helmet';
import Loader from '../Components/Loader';
import Template1 from '../Template/Template1';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../Utils/routeConstant';

const UnclaimedProfileConatiner: React.FC = () => {
  const username = useParams() as { username: string };
  const templateData = useSelector((state: InitialStore) => state?.publicProfile);
  const [publicProfile, setPublicProfile] = useState<IPublicProfile | undefined>(templateData);
  const link = window.location.pathname;
  const history = useHistory();

  useEffect(() => {
    if (templateData?.profileType === 'template') {
      setPublicProfile(templateData);
    } else if (templateData) {
      return history.push(APP_ROUTES.NOTFOUND);
    }
  }, [templateData, history]);
  return (
    <>
      <Helmet>
        <title>{publicProfile?.name || ''} | Rehustle - Sell and Grow with ease</title>
        <meta name="description" content={publicProfile?.description || ''} />
      </Helmet>
      {publicProfile?.loading && publicProfile ? (
        <>
          <Link to={{ pathname: APP_ROUTES.SIGNUP, search: `?ref=${username.username}` }}>
            <div className="fixed top-4 flex justify-center w-full z-50 py-4 lg:px-4">
              <div
                className="mx-4 p-2 bg-toaster-green-light border border-toaster-green-dark text-gray-1 items-center leading-none rounded flex lg:inline-flex"
                role="alert">
                <span className="flex rounded-full bg-toaster-green-dark text-toaster-green-light px-2 py-1 uppercase text-xs font-bold mr-3">
                  Unclaimed! 👋
                </span>
                <span className="font-semibold mr-2 text-left flex-auto">Click here to claim this profile now</span>
                <svg className="fill-current opacity-75 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" />
                </svg>
              </div>
            </div>
          </Link>
          {React.createElement(Template1, {
            name: publicProfile?.name || '',
            description: publicProfile?.description || '',
            socialLinks: publicProfile?.socialLinks,
            profileUrls: publicProfile?.profileUrls,
            products: publicProfile?.products,
            link,
            username: username.username,
            appearance: publicProfile?.appearance,
          })}
        </>
      ) : (
        <React.Fragment>
          <Loader />
        </React.Fragment>
      )}
    </>
  );
};

export default UnclaimedProfileConatiner;
