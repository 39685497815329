import * as alertConstants from '../Constants';
import { ISuccess, IError, IClear } from '../Actions/action.types';
import { initialState, IAlert } from '../helpers/initialState';

export const alert = (alertState = initialState.alert, action: ISuccess | IError | IClear): IAlert => {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        type: alertConstants.SUCCESS,
        message: action.message,
      };
    case alertConstants.ERROR:
      return {
        type: alertConstants.ERROR,
        message: action.message,
      };
    case alertConstants.CLEAR:
      return {};
    default:
      return alertState;
  }
};
