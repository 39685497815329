import React from 'react';

const PrivacyPolicyPage: React.FC = () => {
  return (
    <main className="bg-white">
      <div className="px-4 pb-16 pt-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
          <div className="flex flex-col mb-10 sm:text-center sm:mb-0">
            <a href="/" className="mb-6 mx-auto">
              <div className="flex items-center justify-center w-12 h-12 rounded-full bg-indigo-50 text-brand-default">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                  />
                </svg>
              </div>
            </a>
            <div className="max-w-xl mb-10 mx-auto sm:text-center lg:max-w-2xl md:mb-8">
              <h2 className="max-w-lg text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                <span className="relative inline-block">
                  <svg
                    viewBox="0 0 52 24"
                    fill="currentColor"
                    className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-gray-7 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block">
                    <defs>
                      <pattern id="e77df901-b9d7-4b9b-822e-16b2d410795b" x="0" y="0" width=".135" height=".30">
                        <circle cx="1" cy="1" r=".7" />
                      </pattern>
                    </defs>
                    <rect fill="url(#e77df901-b9d7-4b9b-822e-16b2d410795b)" width="52" height="24" />
                  </svg>
                  <span className="relative">Privacy</span>
                </span>{' '}
                Policy
              </h2>
            </div>
          </div>
        </div>
        <div className="relative">
          <div className="px-4 py-6 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-12 text-gray-500">
            <p className="mt-4">
              {`
              The terms "We" / "Us" / "Our"/”Company” individually and collectively refer to HSV Media Private Limited
              and the terms "You" /"Your" / "Yourself" refer to the users. This Privacy Policy is an electronic record
              in the form of an electronic contract formed under the information Technology Act, 2000 and the rules made
              thereunder and the amended provisions pertaining to electronic documents / records in various statutes as
              amended by the information Technology Act, 2000. This Privacy Policy does not require any physical,
              electronic or digital signature.
              `}
            </p>
            <p className="mt-4">
              This Privacy Policy is a legally binding document between you and HSV Media Private Limited. (both terms
              defined below). The terms of this Privacy Policy will be effective upon your acceptance of the same
              (directly or indirectly in electronic form, by clicking on the I accept tab or by use of the website or by
              other means) and will govern the relationship between you and HSV Media Private Limited. for your use of
              the website “Website” (defined below).
            </p>{' '}
            <p className="mt-4">
              This document is published and shall be construed in accordance with the provisions of the Information
              Technology (reasonable security practices and procedures and sensitive personal data of information)
              rules, 2011 under Information Technology Act, 2000; that require publishing of the Privacy Policy for
              collection, use, storage and transfer of sensitive personal data or information.
            </p>{' '}
            <p className="mt-4">
              Please read this Privacy Policy carefully by using the Website, you indicate that you understand, agree
              and consent to this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do
              not use this Website.
            </p>{' '}
            <p className="mt-4">
              By providing us your Information or by making use of the facilities provided by the Website, You hereby
              consent to the collection, storage, processing and transfer of any or all of Your Personal Information and
              Non-Personal Information by us as specified under this Privacy Policy. You further agree that such
              collection, use, storage and transfer of Your Information shall not cause any loss or wrongful gain to you
              or any other person.
            </p>{' '}
            <p className="mt-4">
              We actively support self-employed, solopreneurs, small businesses in their initial and growth phase with
              respect to digital needs in a sustainable and economical way.
            </p>
            <p className="mt-6 uppercase text-gray-800 font-semibold">User information</p>
            <p className="mt-4">
              To avail certain services on our Websites, users are required to provide certain information for the
              registration process namely: - a) your name, b) email address, c) sex, d) age, e) PIN code, f) credit card
              or debit card details g) medical records and history h) sexual orientation, i) biometric information, j)
              password etc., and / or your occupation, interests, and the like. The Information as supplied by the users
              enables us to improve our sites and provide you the most user-friendly experience.
            </p>
            <p className="mt-4">
              All required information is service dependent and we may use the above said user information to, maintain,
              protect, and improve its services (including advertising services) and for developing new services
            </p>
            <p className="mt-4">
              Such information will not be considered as sensitive if it is freely available and accessible in the
              public domain or is furnished under the Right to Information Act, 2005 or any other law for the time being
              in force.
            </p>
            <p className="mt-6 uppercase text-gray-800 font-semibold">Cookies</p>
            <p className="mt-4">
              To improve the responsiveness of the sites for our users, we may use `{'cookies'}`, or similar electronic
              tools to collect information to assign each visitor a unique, random number as a User Identification (User
              ID) to understand the user`s individual interests using the Identified Computer. Unless you voluntarily
              identify yourself (through registration, for example), we will have no way of knowing who you are, even if
              we assign a cookie to your computer. The only personal information a cookie can contain is information you
              supply (an example of this is when you ask for our Personalised Horoscope). A cookie cannot read data off
              your hard drive. Our advertisers may also assign their own cookies to your browser (if you click on their
              ads), a process that we do not control.
            </p>
            <p className="mt-4">
              Our web servers automatically collect limited information about your computer`s connection to the
              Internet, including your IP address, when you visit our site. (Your IP address is a number that lets
              computers attached to the Internet know where to send you data -- such as the web pages you view.) Your IP
              address does not identify you personally. We use this information to deliver our web pages to you upon
              request, to tailor our site to the interests of our users, to measure traffic within our site and let
              advertisers know the geographic locations from where our visitors come.
            </p>
            <p className="mt-6 uppercase text-gray-800 font-semibold">Links to the other sites</p>
            <p className="mt-4">
              Our policy discloses the privacy practices for our own web site only. Our site provides links to other
              websites also that are beyond our control. We shall in no way be responsible in way for your use of such
              sites.
            </p>
            <p className="mt-6 uppercase text-gray-800 font-semibold">Information sharing</p>
            <p className="mt-4">
              (a) When it is requested or required by law or by any court or governmental agency or authority to
              disclose, for the purpose of verification of identity, or for the prevention, detection, investigation
              including cyber incidents, or for prosecution and punishment of offences. These disclosures are made in
              good faith and belief that such disclosure is reasonably necessary for enforcing these Terms; for
              complying with the applicable laws and regulations.
            </p>
            <p className="mt-4">
              (b) We proposes to share such information within its group companies and officers and employees of such
              group companies for the purpose of processing personal information on its behalf. We also ensure that
              these recipients of such information agree to process such information based on our instructions and in
              compliance with this Privacy Policy and any other appropriate confidentiality and security measures.
            </p>
            <p className="mt-4 uppercase text-gray-800 font-semibold">Information Security</p>
            <p className="mt-4">
              We take appropriate security measures to protect against unauthorized access to or unauthorized
              alteration, disclosure or destruction of data. These include internal reviews of our data collection,
              storage and processing practices and security measures, including appropriate encryption and physical
              security measures to guard against unauthorized access to systems where we store personal data.
            </p>
            <p className="mt-4">
              All information gathered on our Website is securely stored within our controlled database. The database is
              stored on servers secured behind a firewall; access to the servers is password-protected and is strictly
              limited. However, as effective as our security measures are, no security system is impenetrable. We cannot
              guarantee the security of our database, nor can we guarantee that information you supply will not be
              intercepted while being transmitted to us over the Internet. And, of course, any information you include
              in a posting to the discussion areas is available to anyone with Internet access.
            </p>
            <p className="mt-4">
              However the internet is an ever evolving medium. We may change our Privacy Policy from time to time to
              incorporate necessary future changes. Of course, our use of any information we gather will always be
              consistent with the policy under which the information was collected, regardless of what the new policy
              may be.
            </p>
            <p className="mt-4 uppercase text-gray-800 font-semibold">Grievance Redressal</p>
            <p className="mt-4">
              {`Redressal Mechanism: Any complaints, abuse or concerns with regards to content and or comment or breach of these terms shall be immediately 
              informed to the designated Grievance Officer as mentioned below via in writing or through email 
              signed with the electronic signature to info@hsv.digital ("Grievance Officer")`}
            </p>
            <p className="mt-4">
              HSV Media Private Limited
              <br />
              Mr. Himanshu Gupta
              <br />
              www.hsv.digital <br />
              Email: info@hsv.digital
            </p>
          </div>
        </div>
      </div>
    </main>
  );
};

export default PrivacyPolicyPage;
