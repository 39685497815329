/* eslint-disable @typescript-eslint/no-explicit-any */
import { apiUrls } from '../Utils/apiUrls';
import { IProduct } from '../helpers/initialState';
import axiosInstance from '../Utils/interceptor';

export const productService = {
  addProduct,
  updateProduct,
  getAllProducts,
  deleteProduct,
};

async function addProduct(product: IProduct): Promise<void> {
  const { data } = await axiosInstance.post(apiUrls.POST_ADD_NEW_PRODUCT, product);
  return data.result;
}

async function getAllProducts(): Promise<void> {
  const { data } = await axiosInstance.get(apiUrls.GET_ALL_PRODUCT);
  return data.result;
}

async function deleteProduct(product: IProduct): Promise<void> {
  const { data } = await axiosInstance.delete(`${apiUrls.PUT_UPDATE_PRODUCT}/${product._id}`, {});
  return data.result;
}

async function updateProduct(product: IProduct): Promise<void> {
  const { data } = await axiosInstance.put(`${apiUrls.PUT_UPDATE_PRODUCT}/${product._id}`, product);
  return data.result;
}
