/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import * as userConstants from '../Constants';
import { userProfileService } from '../Services';
import { alertActions } from '.';
import { history } from '../helpers';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  IGetUserRequest,
  IGetUserSuccess,
  IGetUserFailure,
  IPostProfileRequest,
  IPostProfileSuccess,
  IPostProfileFailure,
  IGetTwitterUserFailure,
  IGetTwitterUserRequest,
  IGetTwitterUserSuccess,
} from './action.types';
import { IUserProfile } from '../helpers/initialState';
import { loaderActions } from './loader.action';
import toast from 'react-hot-toast';

export const getUserRequest = (): IGetUserRequest => {
  return { type: userConstants.GET_USER_REQUEST };
};

export const getUserSuccess = (response: any): IGetUserSuccess => {
  return { type: userConstants.GET_USER_SUCCESS, response };
};

export const getUserFailure = (error: string): IGetUserFailure => {
  return { type: userConstants.GET_USER_FAILURE, error };
};

export const getTwitterUserRequest = (): IGetTwitterUserRequest => {
  return { type: userConstants.GET_TWITTER_USER_REQUEST };
};

export const getTwitterUserSuccess = (response: any): IGetTwitterUserSuccess => {
  return { type: userConstants.GET_TWITTER_USER_SUCCESS, response };
};

export const getTwitterUserFailure = (error: string): IGetTwitterUserFailure => {
  return { type: userConstants.GET_TWITTER_USER_FAILURE, error };
};

export const postProfileUpadtesRequest = (userProfile: IUserProfile): IPostProfileRequest => {
  return { type: userConstants.POST_PROFILE_REQUEST, userProfile };
};

export const postProfileUpadtesSuccess = (userProfile: IUserProfile): IPostProfileSuccess => {
  return { type: userConstants.POST_PROFILE_SUCCESS, userProfile };
};

export const postProfileUpadtesFailure = (error: string): IPostProfileFailure => {
  return { type: userConstants.POST_PROFILE_FAILURE, error };
};

export const getCurentUser = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(getUserRequest());

    await userProfileService
      .getCurrentUser()
      .then((response: any) => response && dispatch(getUserSuccess(response)))
      .catch((error) => {
        dispatch(getUserFailure(error?.data?.message));
        localStorage.removeItem('token');
      });
  };
};

export const postProfileUpdate = (userProfile: any, from: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(loaderActions.addLoader());
    await dispatch(postProfileUpadtesRequest(userProfile));

    await userProfileService
      .postProfileUpdate(userProfile)
      .then(() => {
        dispatch(postProfileUpadtesSuccess(userProfile));
        from && history.push(from);
        !from && toast.success('Changes Saved Successfully');
      })
      .catch((error) => {
        dispatch(postProfileUpadtesFailure(error?.data?.message));
        //toast.error(error?.data?.message);
        dispatch(alertActions.error(error?.data?.message));
      });
    dispatch(loaderActions.removeLoader());
  };
};

export const getTwitterUser = (username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(getTwitterUserRequest());

    await userProfileService
      .getTwitterUser(username)
      .then((response: any) => response && dispatch(getTwitterUserSuccess(response)))
      .catch((error) => dispatch(getTwitterUserFailure(error?.data?.message)));
  };
};

export const userProfileActions = {
  postProfileUpadtesSuccess,
  getCurentUser,
  postProfileUpdate,
  getTwitterUser,
};
