/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import { IGetPublicProfileFailure, IGetPublicProfileSuccess, IGetPublicProfileRequest } from '../Actions/action.types';
import { initialState, IPublicProfile } from '../helpers/initialState';
import * as publicProfileConstants from '../Constants';

export const publicProfile = (
  publicProfileState = initialState?.publicProfile,
  action: IGetPublicProfileRequest | IGetPublicProfileSuccess | IGetPublicProfileFailure,
): IPublicProfile | {} => {
  switch (action.type) {
    case publicProfileConstants.GET_PUBLIC_PROFILE_REQUEST:
      return { ...publicProfileState, loading: action.loading };
    case publicProfileConstants.GET_PUBLIC_PROFILE_SUCCESS:
      return { ...publicProfileState, ...action.username, loading: true };
    case publicProfileConstants.GET_PUBLIC_PROFILE_FAILURE:
      return {};
    default:
      return {};
  }
};
